
































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import RelationApi from '@/services/RelationApi'
import TokenNumberCard from './chargepass/TokenNumberCard.vue'
import DriverCard from './chargepass/DriverCard.vue'
import SubscriptionCard from './chargepass/SubscriptionCard.vue'
import OverviewCard from './chargepass/OverviewCard.vue'
import SubscriptionTypeApi from '../../services/SubscriptionTypeApi'
import TokenApi from '../../services/TokenApi'
import RelationContactApi from '../../services/RelationContactApi'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    TokenNumberCard,
    DriverCard,
    SubscriptionCard,
    OverviewCard
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', elm: 'chargerInstallationState/elmState' })
  }
})
export default class AddChargepointModal extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isMobile!: boolean

  tokenForm = [
    {
      printednumber : '',
      isValidToken: false,
      description : '',
      user: {
        relationcontactid: 0,
        relationid: 0,
      },
      subscriptiontypeid: -1,
      currentSub: -1,
      completed: false,
      isPublic: false
    }
  ]

  loadingContact = false
  isReset = false
  allContact: any = []

  isSavingToken = false
  summaryErrMSG = ''
  currentStep = 'TokenNumberCard'
  stepTab: any = []
  subscriptionsTab: any = []
  dialog = false
  stepper = 0
  e13 = 1

  mounted () {
    this.generateSteps()
    this.getSub()
    this.fetchData()
  }

  async fetchData () {
    this.loadingContact = true
    if (this.usrDTA) {
      try {
        // get relation contact
        const data: any = await new RelationContactApi().myRelationContacts(this.usrDTA.data.Relation.Id, false) as any
        this.allContact = data.data

        // get relations
        const dt = await new RelationApi(0).relationTree({}, true, '',  0) as any
        dt.data.relations.push(dt.data.parent)
        this.allContact = this.allContact.concat(dt.data.relations)
      } catch (error) {
        console.log(error)
      }
    }
    this.loadingContact = false
  }

  next () {
    this.isReset=false
    this.currentStep = this.stepTab[this.e13].compName
    this.e13++
  }

  back () {
    if (this.e13 !== 0) {
      this.e13--
      this.currentStep = this.stepTab[(this.e13-1)].compName
    }
  }

 async saveToken () {
    try {
      this.isSavingToken = true
      for (let index = 0; index < this.tokenForm.length; index++) {
        const element: any = this.tokenForm[index];
        const linkData = {
          description: element.description,
          printednumber: element.printednumber,
          subscriptiontypeid: element.isPublic ?  element.subscriptiontypeid.Code : 'SUB_COSTLESS',
          relationid: '',
          relationcontactid: ''
        }
        // driver selected
        if (element.user.relationid > 0) {
          linkData.relationid = element.user.relationid
          linkData.relationcontactid = element.user.id
        } else {
          // entity selected
          linkData.relationid = element.user.id
        }
        const linkRslt: any = await new TokenApi().link(linkData)
      }
      this.cancel()
      this.isSavingToken = false
      this.$emit('refresh')
    } catch (e: any) {
      this.isSavingToken = false
      const msg = '$vuetify.errorTXT.' + e.errorcode
      this.summaryErrMSG = this.t(msg)
    }
  }

  async getSub () {
    try {
      const dt = await new SubscriptionTypeApi().getAllSubscriptions() as any
      this.subscriptionsTab = dt.data
    } catch (error) {
      console.log(error)
    }
  }

  generateSteps () {
    if (this.elmDTA.data.Relation.IsCompany === 1) {
      this.stepTab = [
        {title: this.t('$vuetify.myChargeCards.cardNumber'), icon: 'uil uil-check', compName: 'TokenNumberCard'},
        {title: this.t('$vuetify.myChargeCards.linked'), icon: 'uil uil-check', compName: 'DriverCard'},
        {title: this.t('$vuetify.myChargeCards.subscription'), icon: 'uil uil-check', compName: 'SubscriptionCard'},
        {title: this.t('$vuetify.myChargeCards.overview'), icon: 'uil uil-check', compName: 'OverviewCard'}
      ]
    } else {
      this.stepTab = [
        {title: this.t('$vuetify.myChargeCards.cardNumber'), icon: 'uil uil-check', compName: 'TokenNumberCard'},
        {title: this.t('$vuetify.myChargeCards.driverCard'), icon: 'uil uil-check', compName: 'DriverCard'},
        {title: this.t('$vuetify.myChargeCards.subscription'), icon: 'uil uil-check', compName: 'SubscriptionCard'},
        {title: this.t('$vuetify.myChargeCards.overview'), icon: 'uil uil-check', compName: 'OverviewCard'}
      ]
    }
  }

  reset () {
    this.dialog = false
    this.stepper = 1
    this.isReset = true
    this.tokenForm = []
    this.tokenForm = [
    {
      printednumber : '',
      description : '',
      subscriptiontypeid: -1,
      user: {
        relationcontactid: 0,
        relationid: 0,
      },
      isValidToken: false,
      isPublic: false,
      currentSub: -1,
      completed: false
    }]
    this.e13 = 1
    this.currentStep = 'TokenNumberCard'
  }

  closeAll () {
    this.reset()
    this.$emit('refresh')
  }

  get usrDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  cancel () {
    this.reset()
    // this.$emit('refresh')
  }

  refresh () {
    this.dialog = false
    this.tokenForm = [
    {
      printednumber : '',
      description : '',
      subscriptiontypeid: -1,
      user: {
        relationcontactid: 0,
        relationid: 0,
      },
      isValidToken: false,
      isPublic: false,
      currentSub: -1,
      completed: false
    }]
  }
}
