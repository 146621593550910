















import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class StepOne extends Vue {
  next (step: number) {
    // RFID
    if (step === 1) {
      this.$store.commit('chargerInstallationState/setIsGuestCharge', true)
      this.$store.commit('chargerInstallationState/setStep', 'StepPublicPay')
    } else {
      // PLUG & PLAY
      // this.$store.commit('chargerInstallationState/setIsGuestCharge', false)
      this.$store.commit('chargerInstallationState/setStep', 'StepCardAccess')
    }
  }
}
