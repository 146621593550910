









































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import RelationContactApi from '../../services/RelationContactApi'
import NewModal from '../../views/_enduser/NewUserOrganisation.vue'
import RelationSelector from '../../components/relationTree/RelationSelector.vue'
import RelationApi from '../../services/RelationApi'

@Component({
  components: {
    NewModal,
    RelationSelector
  },
  filters: {
    hasPortal (val: string) {
      return val ? 'Driver has a portal access' : 'Driver doesn\'t have a portal access'
    }
  }
})
export default class TokenList extends Vue {
  @Prop(String) readonly id!: string
  @PropSync('form', { type: Object }) formSynced!: any

  search= ''

  data: Array<any> = []
  relationInfo = {}
  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'BCO||SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  selected: any = null
  selectedType = ''
  driverTab: any = []
  driverID = ''
  showModal= false
  current: any = {}
  isEdit = false
  dialogSwitch = false
  headers= [
    { text: this.t('$vuetify.myOrganisation.driverTable.firstName'), value: 'firstname' },
    { text: this.t('$vuetify.myOrganisation.driverTable.lastName'), value: 'lastname' },
    { text: this.t('$vuetify.myOrganisation.driverTable.email'), value: 'email' },
    { text: this.t('$vuetify.myOrganisation.driverTable.address'), value: 'Address' }
  ]

  mounted () {
    this.fetchData()
  }

  refresh () {
    this.fetchData()
  }

  @Watch('payload.relationid')
  relationIdOnChanged (val: any) {
    this.clicked(val)
  }

  @Watch('active', { deep: true })
  activeOnChanged (val: any) {
    if (val.length > 0) {
      this.clicked(val[0])
    }
  }

  async switchDriver () {
    this.dialogSwitch = false
    // console.log('current driver')
    // console.log(this.current)
    this.current.relationid = this.driverID
    try {
      const result = await new RelationContactApi().createUpdate(this.current) as any
      this.notification({
        content: 'Driver switched',
        color: 'info',
        timeout: 6000
      })
      this.refresh()
    } catch (e) {
      this.notification({
        content: 'An error has occures, please contact support.',
        color: 'error',
        timeout: -1
      })
    }
  }

  async clicked (val: any) {
    this.driverID = val
    this.fetchDriverData()
    // console.log('CALL RELATION SELECTOR - GET RELATION BY ID')
    const dt = await new RelationApi(0).getRelationById(val) as any
    this.relationInfo = dt.data
  }

  async fetchDriverData () {
    // console.log('fetching', this.driverID)
    if (this.driverID) {
      // console.log('FETCH BY ID = ' + this.driverID)
      const data = await new RelationContactApi().myRelationContacts(this.driverID) as any
      this.driverTab = data.data
      this.resetDriver()
      // console.log('TAB DRIVER')
      // console.log(data)
    }
  }

  resetDriver () {
    this.payload.firstname = ''
    this.payload.lastname = ''
    this.payload.email = ''
    this.payload.telephone = ''
    this.payload.street = ''
    this.payload.housenumber = ''
    this.payload.postalcode = ''
    this.payload.city = ''
    this.payload.country = ''
  }

  editDriver (d: any) {
    // console.log('EDIT')
    // console.log(d)
    this.current = d
    this.isEdit = true
    this.showModal = true
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async fetchData () {
    // console.log('fetching', this.id)
    if (this.id) {
      // console.log('FETCH BY ID = ' + this.id)
      const data = await new RelationContactApi().myRelationContactsEntityManager(this.id) as any
      // console.log(data)
      this.data = data.data
      // console.log('driver :')
      // console.log(data)
    }
  }

  async getDetails (data: any) {
    this.$router.push({ name: 'navchargepointsdetails', params: { id: data.id } })
  }

  @Watch('id', { immediate: false })
  idOnChanged (val: any, oldVal: any) {
    this.$nextTick(() => this.fetchData())
  }
}
