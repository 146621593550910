















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync, Ref } from 'vue-property-decorator'
import RelationContactApi from '../../../services/RelationContactApi'
import { addressHelper } from '../../../functions/addressHelper'

@Component
export default class StepThreeNewLocation extends Vue {
  @Ref() readonly autocomplete!: any

  @PropSync('form', { type: Object }) formSynced!: any
  contacts: Array<any> = []
  selectedContact: any = {}
  searchVal = ''

  setPlace (data: any) {
    this.formSynced.location = {
      ...addressHelper(data.address_components),
      gps: {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng()
      }
    }
  }

  focus () {
    setTimeout(() => {
      this.autocomplete.$el.focus()
    }, 500)
  }

  updateLocation (data: any) {
    // console.log(data)
    this.formSynced.location.gps.lat = data.latLng.lat()
    this.formSynced.location.gps.lng = data.latLng.lng()
  }

  copyFields () {
    if (this.selectedContact) {
      // console.log(this.selectedContact)
      this.searchVal = `${this.selectedContact.address.street} ${this.selectedContact.address.housenumber} ${this.selectedContact.address.postalcode} ${this.selectedContact.address.city}`
      setTimeout(() => {
        this.autocomplete.$el.focus()
      }, 500)
    }
  }

  async fetchContactList () {
    if (this.formSynced.relationId) {
      const data = await new RelationContactApi().myRelationContacts(this.formSynced.relationId) as any
      this.contacts = data.data
    }
  }

  async fetchContactDetails () {
    if (this.selectedContact.id) {
      const data = await new RelationContactApi().findContactsByRelationContactId(this.selectedContact.id)
    }
  }

  onIntersect (entries: any, observer: any) {
    if (entries[0].isIntersecting) {
      this.fetchContactList()
    }
  }
}
