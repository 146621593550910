


















import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState' })
  }
})
export default class StepOne extends Vue {
  
  next (step: number) {
    // Is not reimbursement
    if (step === 1) {
      if (this.elmDTA.isPlugCharge) {
        this.$store.commit('chargerInstallationState/setPlugChargeIsReimbursement', false)
      } else {
        this.$store.commit('chargerInstallationState/setAccessCardIsReimbursement', false)
      }
      this.$store.commit('chargerInstallationState/setStep', 'StepResume')
    } else {
      if (this.elmDTA.isPlugCharge) {
      this.$store.commit('chargerInstallationState/setPlugChargeIsReimbursement', true)
      } else {
      this.$store.commit('chargerInstallationState/setAccessCardIsReimbursement', true)
      }
      // Is with reimbursement
      this.$store.commit('chargerInstallationState/setStep', 'StepSelectReimburser')
    }
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }
}
