
















import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState' })
  }
})
export default class StepOne extends Vue {
  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  next (step: number) {
    // Visible
    if (step === 1) {
      this.$store.commit('chargerInstallationState/setIsPublishMap', false)
    } else {
      // Not visible
      this.$store.commit('chargerInstallationState/setIsPublishMap', true)
    }
    // check if company (no reimbursment for company)
    if (this.elmDTA.isCompany) {
      this.$store.commit('chargerInstallationState/setStep', 'StepResume')
    } else {
      // this.$store.commit('chargerInstallationState/setStep', 'StepReimburseMainUser')
      if (!this.elmDTA.isGuestCharge) {
        this.$store.commit('chargerInstallationState/setStep', 'StepReimburseMainUser')
      } else {
        this.$store.commit('chargerInstallationState/setStep', 'StepResume')
      }
    }
  }
}
