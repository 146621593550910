<template>
  <div>
    <template v-if="code !== 'PRIVATE'">
      <v-list-item-subtitle class="pt-3">
        <b>{{t('$vuetify.myTokens.contractTerms')}} : </b> <br><br>
        <div v-if="fees.OneTimeFee || fees.MonthlyFee || fees.QuarterlyFee || fees.YearlyFee">
          <span v-if="fees.OneTimeFee">One Time Fee : {{fees.OneTimeFee | formatEuro}} -</span> <span v-if="fees.MonthlyFee">Monthly Fee : {{fees.MonthlyFee | formatEuro}} -</span>
          <span v-if="fees.QuarterlyFee">Quarterly Fee: {{fees.QuarterlyFee | formatEuro}} -</span> <span v-if="fees.YearlyFee">Yearly Fee : {{fees.YearlyFee | formatEuro}}</span>
        </div>
        <div v-else>
          {{t('$vuetify.myTokens.monthlyFee')}} : 0 €
        </div>
      </v-list-item-subtitle>
        <v-list-item-subtitle class="pt-3">
          <b>{{t('$vuetify.myTokens.price')}} :</b>
        </v-list-item-subtitle>
        <v-card outlined class="mt-5" v-if="!isLoading">
            <v-data-table :headers="subRoamHeaders" :items="subTypeEVSEGroupsTab" hide-default-footer hide-default-header>
                <template v-slot:body="{ items, headers }">
                  <thead>
                    <tr>
                      <template v-for="item in headers">
                        <th :key="item.value" v-if="!isRemoveHeader(items, item.value)">
                          {{item.text}}
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <td>{{evTypeLabels[item.EvType]}}</td>
                      <td v-if="!isRemoveField(items, 'startfee')">{{item.startfee | formatEuro}}</td>
                      <td v-if="!isRemoveField(items, 'roamingfee')">
                        {{item.roamingfee | formatEuro}}
                      </td>
                      <td v-if="!isRemoveField(items, 'parkingfee')">
                        {{item.parkingfee | formatEuro}} ( {{t('$vuetify.myTokens.from')}} : {{item.intervalnoparkingfeeto}} h - {{t('$vuetify.myTokens.to')}} : {{item.intervalnoparkingfeefrom}} h )
                      </td>
                      <td v-if="!isRemoveField(items, 'kwhfee')">{{item.kwhfee | formatEuro}}</td>
                      <td v-if="!isRemoveField(items, 'rotationfee')">
                        {{item.rotationfee | formatEuro}} <span v-if="item.intervalnorotationfeefrom">( {{t('$vuetify.myTokens.from')}} : {{item.intervalnorotationfeeto}} h - {{t('$vuetify.myTokens.to')}} : {{item.intervalnorotationfeefrom}} h )</span>
                      </td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier1')">{{item.kwhfeemultiplier1 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier2')">{{item.kwhfeemultiplier2 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier3')">{{item.kwhfeemultiplier3 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier4')">{{item.kwhfeemultiplier4 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier5')">{{item.kwhfeemultiplier5 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier6')">{{item.kwhfeemultiplier6 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'kwhfeemultiplier7')">{{item.kwhfeemultiplier7 | formatNA}}</td>
                      <td v-if="!isRemoveField(items, 'daymax')">{{item.daymax | formatNA}}</td>
                    </tr>
                  </tbody>
                </template>
            </v-data-table>
            <v-card-text>
              {{t('$vuetify.myChargeCards.vatRule')}}
            </v-card-text>
        </v-card>
        <div v-else>
          <v-btn loading class="white--text" text></v-btn>
        </div>
      </template>
      <template v-else>
        Your chargecard can only charge on chargers that are linked to the customer
      </template>
  </div>
</template>
<script>
/* eslint-disable */
import SubscriptionTypeApi from '../../services/SubscriptionTypeApi'
import moment from 'moment'

export default {
  data () {
    return {
      fees: {},
      isLoading: false,
      showForm: false,
      evseGroupsModel: {
        TariffCode: '',
        EvType: 'AC',
        Category: '',
        Location1: '',
        Location2: '',
        LandOwnerId: '',
        OwnerId: '',
        SubscriptionTypeCode: '',
        CpLogicalGroupId: '',
        Tariff: {
          Code: '',
          StartDate: '',
          StartDateH: '',
          KwhFee: '',
          RotationFee: '',
          StartFee: '',
          ParkingFee: '',
          DayMax: '',
          CalculationType: '',
          MinutesNoParkingFee: '',
          IntervalNoParkingFeeFrom: '',
          IntervalNoParkingFeeTo: '',
          MinutesNoRotationFee: '',
          IntervalNoRotationFeeFrom: '',
          IntervalNoRotationFeeTo: '' 
        }
      },
      calculationtypeLabel: {
        1: 'Calculated',
        2: 'UpperLevel',
        3: 'Provided'
      },
      evTypeLabels: {
        AC: this.t('$vuetify.myTokens.publicAC'),
        DC: this.t('$vuetify.myTokens.publicDC'),
        BIKE: this.t('$vuetify.myTokens.publicBike'),
        ROAMING: this.t('$vuetify.myTokens.interop'),
        PRIVATE: this.t('$vuetify.myTokens.private')
      },
      evType: [
        {
          label: this.t('$vuetify.myTokens.publicAC'),
          value: 'AC'
        },
        {
          label: this.t('$vuetify.myTokens.publicDC'),
          value: 'DC'
        },
        {
          label: this.t('$vuetify.myTokens.publicBike'),
          value: 'BIKE'
        },
        {
          label: this.t('$vuetify.myTokens.interop'),
          value: 'ROAMING'
        },
        {
          label: this.t('$vuetify.myTokens.private'),
          value: 'PRIVATE'
        }],
      calcTpe: [{'label': 'Calculated', 'value': 1}, {'label': 'UpperLevel', 'value': 2}, {'label': 'Provided', 'value': 3}],
      dt: null,
      panel: [0, 1],
      tariffData: {},
      subRoamHeaders: [
        { text: 'EV type', value: 'EvType', sortable: false },
        // { text: 'Start Date', value: 'startdate', sortable: false },
        // { text: 'End Date', value: 'enddate', sortable: false },
        // { text: 'Calculation Type', value: 'calculationtype', sortable: false },
        { text: this.t('$vuetify.myTokens.startFee'), value: 'startfee', sortable: false },
        { text: this.t('$vuetify.myTokens.kwhFee'), value: 'kwhfee', sortable: false },
        { text: this.t('$vuetify.myTokens.rotationFee'), value: 'rotationfee', sortable: false },
        { text: this.t('$vuetify.myTokens.roamingFee'), value: 'roamingfee', sortable: false },
        { text: this.t('$vuetify.myTokens.parkingFee'), value: 'parkingfee', sortable: false },
        { text: '   0 - 1 kw', value: 'kwhfeemultiplier1', sortable: false },
        { text: '   1 - 2,2 kw', value: 'kwhfeemultiplier2', sortable: false },
        { text: '   2,2 - 4 kw', value: 'kwhfeemultiplier3', sortable: false },
        { text: '   4 - 8 kw', value: 'kwhfeemultiplier4', sortable: false },
        { text: '   8 - 13 kw', value: 'kwhfeemultiplier5', sortable: false },
        { text: '   13 - 22 kw', value: 'kwhfeemultiplier6', sortable: false },
        { text: '   > 22 kw', value: 'kwhfeemultiplier7', sortable: false },
        { text: this.t('$vuetify.myTokens.daymax'), value: 'daymax', sortable: false }
      ],
      subTypeEVSEGroupsObj: {
        startfee: { AC: '', DC: '', ROAMING: '' },
        kwhfee: { AC: '', DC: '', ROAMING: '' },
        rotationfee: { AC: '', DC: '', ROAMING: '' },
        intervalnorotationfeefrom: { AC: '', DC: '', ROAMING: '' },
        intervalnorotationfeeto: { AC: '', DC: '', ROAMING: '' },
        roamingfee: { AC: '', DC: '', ROAMING: '' },
        parkingfee: { AC: '', DC: '', ROAMING: '' },
        intervalnoparkingfeefrom: { AC: '', DC: '', ROAMING: '' },
        intervalnoparkingfeeto: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier1: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier2: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier3: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier4: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier5: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier6: { AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier7: { AC: '', DC: '', ROAMING: '' },
        daymax: { AC: '', DC: '', ROAMING: '' }
      },
      subTypeEVSEGroupsTab: [],
      subTypeEVSEGroups: [],
      tariffs: {
        data: {}
      }
    }
  },
  mounted () {
    this.getData(this.code)
  },
  methods: {
    async getData(val) {
      try {
        this.isLoading = true
        const config = {
          type: this.usrType,
          caching: 0
        }
        let tarrifObj = await new SubscriptionTypeApi().getSubscriptions(val, config)
        this.subTypeEVSEGroupsTab = tarrifObj.data.Tariffs
        this.fees = tarrifObj.data
        this.isLoading = false
        // console.log(this.subTypeEVSEGroupsTab)
        /* const config = {
          type: this.usrType,
          caching: 0
        }
        // TODO ACCESS DENIED
        this.isLoading = true
        let tarrifObj = await new SubscriptionTypeApi().getSubscriptions(val, config)
        this.tariffs = tarrifObj
        this.subTypeEVSEGroups = tarrifObj.data.Tariffs
        let tmpTab = []
        for (let index = 0;  this.subTypeEVSEGroups && index < this.subTypeEVSEGroups.length; index++) {
          let elm = this.subTypeEVSEGroups[index]
          if (elm.kwhfee){
            tmpTab.push(elm)
          }
        }
        this.subTypeEVSEGroups = tmpTab
        console.log(this.subTypeEVSEGroups)
        this.isLoading = false */
      } catch (e) {
        console.log(e)
      }
    },
    refresh () {
      this.getData(this.code)
    },
    formatEuro (value) {
      let val = 0
      val = (value / 1).toFixed(2).replace('.', ',')
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
      return val
    },
    checkCpo () {
      //return !hasRole('Cpo')
      return true
    },
    init (data) {
      let origKey = ''
      let newO = {}
      let newKey = ''
      for (origKey in data) {
        newKey = origKey.toLowerCase()
        if (newKey !== 'subscriptiontypeevsegroups') {
          newO[newKey] = data[origKey]
        } else {
          newO[origKey] = data[origKey]
        }
      }
      this.tariffData = newO
      data = newO.SubscriptionTypeEVSEGroups
      if (data !== undefined) {
        for (let index = 0; index < data.length; index++) {
          const tariff = data[index].Tariff
          origKey = ''
          newO = {}
          newKey = ''
          for (origKey in tariff) {
            newKey = origKey.toLowerCase()
            newO[newKey] = tariff[origKey]
          }
          let evType = data[index].EvType
          if (evType === undefined) {
            evType = data[index].evtype
          }
          const element = this.subTypeEVSEGroupsObj
          newKey = ''
          for (const field in element) {
            newKey = field.toLowerCase()
            if (newO[field] !== undefined) {
              this.subTypeEVSEGroupsObj[newKey][evType] = newO[field]
            }
          }
        }
        this.subTypeEVSEGroupsTab = []
        for (let i in this.subTypeEVSEGroupsObj) {
          let element = this.subTypeEVSEGroupsObj[i]
          if (
            !(element.AC === '' && element.DC === '' && element.ROAMING === '')
          ) {
            if (i === 'rotationfee') {
              element.AC = this.setText(
                element.AC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.AC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.AC
              )
              element.DC = this.setText(
                element.DC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.DC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.DC
              )
              element.ROAMING = this.setText(
                element.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.ROAMING
              )
            }
            if (i === 'parkingfee') {
              element.AC = this.setText(
                element.AC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.AC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.AC
              )
              element.DC = this.setText(
                element.DC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.DC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.DC
              )
              element.ROAMING = this.setText(
                element.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.ROAMING
              )
            }
            if (
              i !== 'intervalnorotationfeefrom' &&
              i !== 'intervalnorotationfeeto' &&
              i !== 'intervalnoparkingfeeto' &&
              i !== 'intervalnoparkingfeefrom'
            ) {
              if (i === 'kwhfeemultiplier1') {
                this.subTypeEVSEGroupsTab.push({
                  EV: 'Level Fee ( / 15 min) : ',
                  AC: ' ',
                  DC: ' ',
                  ROAMING: ' '
                })
              }
              i = this.getType(i)
              if (i !== 'Connection Rate (Per Min)' && i !== 'parkingfee') {
                element.AC = this.formatEuro(element.AC)
                element.DC = this.formatEuro(element.DC)
                element.ROAMING = this.formatEuro(element.ROAMING)
              }
              let obj = {
                EV: i,
                AC: element.AC,
                DC: element.DC,
                ROAMING: element.ROAMING
              }
              this.subTypeEVSEGroupsTab.push(obj)
              obj = {}
            }
          }
          element = {}
        }
      }
    },
    getType (i) {
      switch (i) {
        case 'rotationfee':
          return 'Connection Rate (Per Min)'
        case 'roamingfee':
          return 'Roaming Fee'
        case 'kwhfeemultiplier1':
          return '   0 - 1 kw'
        case 'kwhfeemultiplier2':
          return '   1 - 2,2 kw'
        case 'kwhfeemultiplier3':
          return '   2,2 - 4 kw'
        case 'kwhfeemultiplier4':
          return '   4 - 8 kw'
        case 'kwhfeemultiplier5':
          return '   8 - 13 kw'
        case 'kwhfeemultiplier6':
          return '   13 - 22 kw'
        case 'kwhfeemultiplier7':
          return '   > 22 kw'
        default:
          return i
      }
    },
    setText (val, from, to) {
      if (val !== '' && from !== '' && to !== '') {
        return (
          this.formatEuro(val) + ' ( ' + this.t('$vuetify.myTokens.from') + ' : ' + to + ' - ' + this.t('$vuetify.myTokens.to') + ' : ' + from + ' )'
        )
      }
    },
    isRemoveField (tab, search) {
      let removeField = false
      let stopSearch = false
      for (let index = 0; index < tab.length && !removeField && !stopSearch; index++) {
        if (!(tab[index][search])) {
          removeField = true
        } else {
          stopSearch = true
        }
      }
      return removeField
    },
    isRemoveHeader (tab, search) {
      let removeField = false
      let stopSearch = false
      // console.log(tab)
      // console.log(tab.length)
      // console.log(search)
      for (let index = 0; index < tab.length && !removeField && !stopSearch; index++) {
        // console.log('in for')
        // console.log(tab)
        // console.log(tab.length)
        // console.log(search)
        if (!(tab[index][search])) {
          removeField = true
        } else {
          stopSearch = true
        }
      }
      return removeField
    }
  },
  filters: {
    formatLA (text) {
      return text !== undefined && text !== '' ? text : ' - '
    },
    formatType (text) {
      let val = ''
      switch (text) {
        case 1: val = 'PARTNER-CPO'; break
        case 2: val = 'VCPO-CPO'; break
        case 3: val = 'CPO-MSP'; break
        case 4: val = 'MSP-VMSP'; break
        default: val = text; break
      }
      return val
    },
    formatEuro (value) {
      let val = 0
      val = (value / 1).toFixed(2).replace('.', ',')
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
      return val
    },
    formatNA (text) {
      return text !== undefined && text !== '' ? text : ' - '
    },
    formatDateTime (val) {
      return (moment(val, 'YYYY-MM-DD')).format('DD/MM/YYYY')
    }
  },
  watch: {
    obj (val) {
      // this.init(val)
    },
    code (val) {
      this.getData(val)
    }
  },
  props: ['obj', 'code']
}
</script>

<style lang="css" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
