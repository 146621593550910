



































































































































































































































































































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import RelationContactApi from '../../../services/RelationContactApi'
import RelationApi from '../../../services/RelationApi'
import {
  isRequired,
  isEmail,
  isPhone,
  isTexte,
  isAddress,
  isHouseNumber,
  hasIbanSpecialChars
} from '../../../functions/formRules'

@Component({
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState' })
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any
  @Prop(Object) readonly usrDTA: any
  @Ref() readonly RelationContactForm!: any
  @Ref() readonly RelationExistingForm!: any

  // form
  isFormValid = true
  ibanErrorMsg = ''
  valid = true
  // form error
  error = false
  errorMessages = ''

  errorProvider = false
  errorProviderMessages = ''

  loading = false
  state = 0
  isCheck = false

  tab = null

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // define the default value
  value = null

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  items: Array<any> = []

  isLoading = false
  data: Array<any> = []
  usrSelect: any = null
  isLoadingTree = false
  isSaving = false

  required: Array<any> = [isRequired('This field is required')]

  rulesText: Array<any> = [
    isRequired('This field is required'),
    isTexte('Invalid')
  ]

  rulesEmail: Array<any> = [
    isRequired('This field is required'),
    isEmail('Invalid Email address')
  ]

  rulesPhone: Array<any> = [isPhone('Invalid phone should be like 04XXXXXXXX')]

  rulesAddress: Array<any> = [
    isRequired('This field is required'),
    isAddress('This field is required')
  ]

  rulesHouseNumber: Array<any> = [
    isRequired('This field is required'),
    isHouseNumber('This field is required')
  ]

  fieldsRelationContact: any = {
    relationid: null,
    firstname: '',
    lastname: '',
    telephone: '',
    email: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    remarkinternal: '',
    remarkpublic: '',
    isActive: null,
    hasportal: null,
    isentitymanager: null
  }

  normalizer(node: any) {
    return {
      id: node.id,
      label: node.description,
      children: node.children
    }
  }

  mounted() {
    this.fetchData(true)
    this.getTree()
  }

  async checkIBAN(val: string) {
    if (val !== '') {
      if (hasIbanSpecialChars(val)) {
        this.formSynced.convert.bankaccountbic = ''
        this.ibanErrorMsg =
          "Iban shouldn't contains special characters or spaces."
      } else {
        try {
          const result = (await new RelationApi().checkIBAN(val)) as any
          if (result.valid) {
            this.formSynced.convert.bankaccountbic = result.bankData.bic
            this.ibanErrorMsg = ''
          } else {
            this.formSynced.convert.bankaccountbic = ''
            this.ibanErrorMsg = 'Invalid IBAN'
          }
        } catch (error: any) {
          this.formSynced.convert.bankaccountbic = ''
          this.ibanErrorMsg = 'Invalid IBAN'
        }
      }
    }
  }

  async getTree() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationAllTree(true, '')) as any
    this.isLoadingTree = false
    this.items = [this.formatTree(dt.data, true)]
  }

  formatTree(object: any, isRoot = false) {
    if (isRoot) {
      const obj = object.parent
      if (obj.children) {
        delete object.parent
        obj.children = []
        const keys = Object.keys(object)
        for (let i = 0; i < keys.length; i++) {
          obj.children = obj.children.concat(object[keys[i]])
        }
      }
      return obj
    } else {
      let array: Array<any> = []
      delete object.parent
      const keys = Object.keys(object)
      for (let i = 0; i < keys.length; i++) {
        array = array.concat(object[keys[i]])
      }
      return array
    }
  }

  async fetchData(isAlldrivers = false) {
    this.isLoading = true
    if (this.usrDTA.data.Relation.Id) {
      const data: any = (await new RelationContactApi().myRelationContacts(
        this.usrDTA.data.Relation.Id,
        !isAlldrivers
      )) as any
      this.data = data.data
      for (let index = 0; index < this.data.length; index++) {
        const element: any = this.data[index]
        if (element.address) {
          element.formattedAddress =
            element.address.street +
            ' ' +
            element.address.housenumber +
            ' - ' +
            element.address.postalcode +
            ' ' +
            element.address.city +
            ' ' +
            element.address.country
        }
        this.data[index] = element
      }
    }
    this.isLoading = false
  }

  previous() {
    // this.$store.commit('chargerInstallationState/setIsCompany', false)
    this.formSynced.relationId = ''
    this.formSynced.relationContactId = ''
    this.formSynced.convert = {
      tab: 0,
      relationid: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      remarkinternal: '',
      remarkpublic: '',
      remark: '',
      isActive: null,
      hasportal: null,
      isentitymanager: null,
      bankaccountiban: '',
      bankaccountbic: ''
    }
    this.$store.commit('chargerInstallationState/setRelationId', '')
    this.$store.commit('chargerInstallationState/setRelationContactId', '')
    this.$emit('back')
  }

  reset() {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  showError(msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  cleanUp() {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  async nextStep() {
    // Check if Home Charger:
    if (this.elmDTA.isCompany) {
      // console.log(this.fieldsRelationContact)
      // console.log(this.formSynced.relationId)
      // get address selected user:
      const dt = (await new RelationApi(0).getRelationById(
        this.formSynced.relationId
      )) as any
      const dtElm = dt.data
      // console.log(dtElm)
      this.$store.commit(
        'chargerInstallationState/setRelationId',
        this.formSynced.relationId
      )
      // this.$store.commit('chargerInstallationState/setAddress', dtElm)
      this.formSynced.Location = {
        Street: dtElm.Street,
        City: dtElm.City,
        Name: '',
        Description: '',
        HouseNumber: dtElm.HouseNumber,
        PostalCode: dtElm.PostalCode,
        Latitude: 0,
        Longitude: 0,
        Country: dtElm.Country
      }
      // console.log(this.elmDTA)
      this.$emit('next')
    } else {
      // Check if existing user
      if (this.formSynced.convert.tab === 0) {
        // Create User
        // await this.createUser()
        // console.log('TODO CREATION USER')
        if (this.RelationContactForm.validate()) {
          this.formSynced.Location = {
            Street: this.formSynced.convert.street,
            City: this.formSynced.convert.city,
            Name: '',
            Description: '',
            HouseNumber: this.formSynced.convert.housenumber,
            PostalCode: this.formSynced.convert.postalcode,
            Latitude: 0,
            Longitude: 0,
            Country: this.formSynced.convert.country
          }
          this.$emit('next')
        }
      } else {
        // console.log('CHECK ALREADY CREATED USER')
        // console.log(this.RelationExistingForm)
        // make validation form
        if (this.RelationExistingForm.validate()) {
          // console.log(this.formSynced.relationContactId)
          const dt = (await new RelationContactApi(
            0
          ).findContactsByRelationContactId(
            this.formSynced.relationContactId
          )) as any
          const dtElm = dt.data
          // console.log(dtElm)
          this.formSynced.Location = {
            Street: dtElm.Street,
            City: dtElm.City,
            Name: '',
            Description: '',
            HouseNumber: dtElm.HouseNumber,
            PostalCode: dtElm.PostalCode,
            Latitude: 0,
            Longitude: 0,
            Country: dtElm.Country
          }
          this.$store.commit(
            'chargerInstallationState/setRelationContactId',
            this.formSynced.relationContactId
          )
          this.$emit('next')
        }
      }
    }
    // Create User
    // Set relation ID and relation contact ID
  }

  async createUser() {
    // Init relation ID
    const payload: any = {}
    const keys = Object.keys(this.fieldsRelationContact)
    keys.forEach((e) => {
      payload[e.toLowerCase()] = this.fieldsRelationContact[e]
    })
    // payload.relationid = this.parent.Id
    // console.log(payload)
    // END
    // console.log('SAVE DRIVER')
    if (this.RelationContactForm.validate()) {
      this.isSaving = true
      // Init relation ID
      const payload: any = {}
      const keys = Object.keys(this.fieldsRelationContact)
      keys.forEach((e) => {
        payload[e.toLowerCase()] = this.fieldsRelationContact[e]
      })
      // payload.relationid = this.parent.Id
      try {
        // Create Driver
        const result = (await new RelationContactApi().createUpdate(
          payload
        )) as any
        const dta = { id: result.data.Id }
        this.$store.commit(
          'chargerInstallationState/setRelationContactId',
          dta.id
        )
        this.isSaving = false
        // this.valueSynced = false
        // this.$emit('refresh')
      } catch (e) {
        /* this.notification({
          content: 'Unknown error occurred, please contact services',
          color: 'error',
          timeout: -1
        }) */
      }
      this.isSaving = false
    }
  }

  cancel() {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  resetErrors() {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  get elmDTA(): any {
    // @ts-ignore
    return this.elm
  }
}
