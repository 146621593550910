<template>
    <v-dialog v-model="dialog" fullscreen persistent>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small class="mx-2">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
            <v-toolbar dark color="primary" class="pl-5">
              <v-toolbar-title>{{t('$vuetify.tarrifSetup.newTariff')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="save(code)">{{t('$vuetify.generic.save')}}</v-btn>
                <v-btn dark text @click="dialog = false">{{t('$vuetify.generic.cancel')}}</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12><h3>{{t('$vuetify.tarrifSetup.subType')}} :</h3></v-flex>
                    <v-flex xs6 v-if="isModule">
                        <v-select
                            v-model="subscriptionType.RelationType"
                            :items="relationTypeTab"
                            item-text="label"
                            item-value="value"
                            :label="t('$vuetify.tarrifSetup.relationType')"
                        ></v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      :label="t('$vuetify.tarrifSetup.startDate')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      {{t('$vuetify.generic.cancel')}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(startDate)"
                    >
                      {{t('$vuetify.generic.ok')}}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field
                            :color="getCodeColor" :label="t('$vuetify.tarrifSetup.code')" v-model="code" required>
                        </v-text-field>
                        <div v-if="exist" class="red--text text--lighten-1">
                            {{t('$vuetify.tarrifSetup.alreadyExist')}}
                        </div>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field :label="t('$vuetify.tarrifSetup.description')" v-model="subscriptionType.Description" required></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                        <v-text-field :label="t('$vuetify.tarrifSetup.oneTimeFee')" v-model="subscriptionType.OneTimeFee" required></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                        <v-text-field :label="t('$vuetify.tarrifSetup.monthlyFee')" v-model="subscriptionType.MonthlyFee" required></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                        <v-text-field :label="t('$vuetify.tarrifSetup.quarterlyFee')" v-model="subscriptionType.QuarterlyFee" required></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                        <v-text-field :label="t('$vuetify.tarrifSetup.yearlyFee')" v-model="subscriptionType.YearlyFee" required></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap row>
                    <v-flex xs12><h3>{{t('$vuetify.tarrifSetup.evseGroups')}} : </h3></v-flex>
                    <v-flex xs2>
                      <v-flex xs12>
                          <v-toolbar color="#EEEEEE" dark  style="height:50px!important;">
                              <v-toolbar-title style="color:black;margin-bottom:15px;"><span >{{evseGroupsModel[0].EvType}}</span></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon small text @click="evseGroupsModel[0].isEditing = !evseGroupsModel[0].isEditing">
                                  <v-icon :color="evseGroupsModel[0].isEditing ? 'green' : 'blue'" v-text="evseGroupsModel[0].isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'" ></v-icon>
                              </v-btn>
                          </v-toolbar>
                          <div style="background-color: #EEEEEE;" class="pa-4" v-if="evseGroupsModel[0].isEditing">
                          <v-layout row wrap>
                              <v-flex xs12>
                                  <v-select
                                      v-model="evseGroupsModel[0].Tariff.CalculationType"
                                      :items="calcTpe"
                                      :label="t('$vuetify.tarrifSetup.calcType')"
                                      item-text="label"
                                      item-value="value"
                                      :disabled="!evseGroupsModel[0].isEditing"
                                  ></v-select>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.startFee')" type="number" v-model="evseGroupsModel[0].Tariff.StartFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.kwhFee')" type="number" v-model="evseGroupsModel[0].Tariff.KwhFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.rotationFee')" type="number" v-model="evseGroupsModel[0].Tariff.RotationFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[0].Tariff.MinutesNoRotationFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[0].Tariff.IntervalNoRotationFeeFrom" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.noFeeTo')" type="number" v-model="evseGroupsModel[0].Tariff.IntervalNoRotationFeeTo" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.parkinFee')" type="number" v-model="evseGroupsModel[0].Tariff.ParkingFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[0].Tariff.MinutesNoParkingFee" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[0].Tariff.IntervalNoParkingFeeFrom" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.to')" type="number" v-model="evseGroupsModel[0].Tariff.IntervalNoParkingFeeTo" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                                  <v-text-field :label="t('$vuetify.tarrifSetup.dayMax')" type="number" v-model="evseGroupsModel[0].Tariff.DayMax" :disabled="!evseGroupsModel[0].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          </div>
                      </v-flex>
                    </v-flex>

                    <v-flex xs2>
                      <v-flex xs12>
                          <v-toolbar color="#EEEEEE" dark  style="height:50px!important;" >
                          <v-toolbar-title style="color:black;margin-bottom:15px;"><span >{{evseGroupsModel[1].EvType}}</span></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon small text @click="evseGroupsModel[1].isEditing = !evseGroupsModel[1].isEditing"><v-icon :color="evseGroupsModel[1].isEditing ? 'green' : 'blue'" v-text="evseGroupsModel[1].isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'" ></v-icon></v-btn>
                          </v-toolbar>
                          <div style="background-color: #EEEEEE;" class="pa-4" v-if="evseGroupsModel[1].isEditing">
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-select
                                  :items="calcTpe"
                                  v-model="evseGroupsModel[1].Tariff.CalculationType"
                                  :label="t('$vuetify.tarrifSetup.calcType')"
                                  item-text="label"
                                  item-value="value"
                                  :disabled="!evseGroupsModel[1].isEditing"
                              ></v-select>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.startFee')" type="number" v-model="evseGroupsModel[1].Tariff.StartFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.kwhFee')" type="number" v-model="evseGroupsModel[1].Tariff.KwhFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.rotationFee')" type="number" v-model="evseGroupsModel[1].Tariff.RotationFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[1].Tariff.MinutesNoRotationFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[1].Tariff.IntervalNoRotationFeeFrom" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeTo')" type="number" v-model="evseGroupsModel[1].Tariff.IntervalNoRotationFeeTo" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.parkinFee')" type="number" v-model="evseGroupsModel[1].Tariff.ParkingFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[1].Tariff.MinutesNoParkingFee" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[1].Tariff.IntervalNoParkingFeeFrom" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.to')" type="number" v-model="evseGroupsModel[1].Tariff.IntervalNoParkingFeeTo" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.dayMax')" type="number" v-model="evseGroupsModel[1].Tariff.DayMax" :disabled="!evseGroupsModel[1].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          </div>
                      </v-flex>
                    </v-flex>

                    <v-flex xs2>
                      <v-flex xs12>
                          <v-toolbar color="#EEEEEE" dark  style="height:50px!important;">
                          <v-toolbar-title style="color:black;margin-bottom:15px;"><span >{{evseGroupsModel[2].EvType}}</span></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon small text @click="evseGroupsModel[2].isEditing = !evseGroupsModel[2].isEditing"><v-icon :color="evseGroupsModel[2].isEditing ? 'green' : 'blue'" v-text="evseGroupsModel[2].isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'" ></v-icon></v-btn>
                          </v-toolbar>
                          <div style="background-color: #EEEEEE;" class="pa-4" v-if="evseGroupsModel[2].isEditing">
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-select
                                  :items="calcTpe"
                                  v-model="evseGroupsModel[2].Tariff.CalculationType"
                                  :label="t('$vuetify.tarrifSetup.calcType')"
                                  item-text="label"
                                  item-value="value"
                                  :disabled="!evseGroupsModel[2].isEditing"
                              ></v-select>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.startFee')" type="number" v-model="evseGroupsModel[2].Tariff.StartFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.kwhFee')" type="number" v-model="evseGroupsModel[2].Tariff.KwhFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.rotationFee')" type="number" v-model="evseGroupsModel[2].Tariff.RotationFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[2].Tariff.MinutesNoRotationFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[2].Tariff.IntervalNoRotationFeeFrom" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeTo')" type="number" v-model="evseGroupsModel[2].Tariff.IntervalNoRotationFeeTo" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.parkinFee')" type="number" v-model="evseGroupsModel[2].Tariff.ParkingFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[2].Tariff.MinutesNoParkingFee" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[2].Tariff.IntervalNoParkingFeeFrom" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.to')" type="number" v-model="evseGroupsModel[2].Tariff.IntervalNoParkingFeeTo" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.dayMax')" type="number" v-model="evseGroupsModel[2].Tariff.DayMax" :disabled="!evseGroupsModel[2].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          </div>
                      </v-flex>
                    </v-flex>

                    <v-flex xs2>
                      <v-flex xs12>
                          <v-toolbar color="#EEEEEE" dark  style="height:50px!important;">
                          <v-toolbar-title style="color:black;margin-bottom:15px;"><span >{{evseGroupsModel[3].EvType}}</span></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon small text @click="evseGroupsModel[3].isEditing = !evseGroupsModel[3].isEditing"><v-icon :color="evseGroupsModel[3].isEditing ? 'green' : 'blue'" v-text="evseGroupsModel[3].isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'" ></v-icon></v-btn>
                          </v-toolbar>
                          <div style="background-color: #EEEEEE;" class="pa-4" v-if="evseGroupsModel[3].isEditing">
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-select
                                  :items="calcTpe"
                                  v-model="evseGroupsModel[3].Tariff.CalculationType"
                                  :label="t('$vuetify.tarrifSetup.calcType')"
                                  item-text="label"
                                  item-value="value"
                                  :disabled="!evseGroupsModel[3].isEditing"
                              ></v-select>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.startFee')" type="number" v-model="evseGroupsModel[3].Tariff.StartFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.kwhFee')" type="number" v-model="evseGroupsModel[3].Tariff.KwhFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.rotationFee')" type="number" v-model="evseGroupsModel[3].Tariff.RotationFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[3].Tariff.MinutesNoRotationFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[3].Tariff.IntervalNoRotationFeeFrom" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeTo')" type="number" v-model="evseGroupsModel[3].Tariff.IntervalNoRotationFeeTo" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.parkinFee')" type="number" v-model="evseGroupsModel[3].Tariff.ParkingFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[3].Tariff.MinutesNoParkingFee" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[3].Tariff.IntervalNoParkingFeeFrom" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.to')" type="number" v-model="evseGroupsModel[3].Tariff.IntervalNoParkingFeeTo" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.dayMax')" type="number" v-model="evseGroupsModel[3].Tariff.DayMax" :disabled="!evseGroupsModel[3].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          </div>
                      </v-flex>
                    </v-flex>

                    <v-flex xs2>
                      <v-flex xs12>
                          <v-toolbar color="#EEEEEE" dark  style="height:50px!important;">
                          <v-toolbar-title style="color:black;margin-bottom:15px;"><span >{{evseGroupsModel[4].EvType}}</span></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon small text @click="evseGroupsModel[4].isEditing = !evseGroupsModel[4].isEditing"><v-icon :color="evseGroupsModel[3].isEditing ? 'green' : 'blue'" v-text="evseGroupsModel[3].isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'" ></v-icon></v-btn>
                          </v-toolbar>
                          <div style="background-color: #EEEEEE;" class="pa-4" v-if="evseGroupsModel[4].isEditing">
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-select
                                  :items="calcTpe"
                                  v-model="evseGroupsModel[4].Tariff.CalculationType"
                                  :label="t('$vuetify.tarrifSetup.calcType')"
                                  item-text="label"
                                  item-value="value"
                                  :disabled="!evseGroupsModel[4].isEditing"
                              ></v-select>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.startFee')" type="number" v-model="evseGroupsModel[4].Tariff.StartFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.kwhFee')" type="number" v-model="evseGroupsModel[4].Tariff.KwhFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.rotationFee')" type="number" v-model="evseGroupsModel[4].Tariff.RotationFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[4].Tariff.MinutesNoRotationFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[4].Tariff.IntervalNoRotationFeeFrom" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeTo')" type="number" v-model="evseGroupsModel[4].Tariff.IntervalNoRotationFeeTo" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.parkinFee')" type="number" v-model="evseGroupsModel[4].Tariff.ParkingFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.minNoFee')" type="number" v-model="evseGroupsModel[4].Tariff.MinutesNoParkingFee" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.noFeeFrom')" type="number" v-model="evseGroupsModel[4].Tariff.IntervalNoParkingFeeFrom" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                              <v-flex xs3>
                              <v-text-field :label="t('$vuetify.tarrifSetup.to')" type="number" v-model="evseGroupsModel[4].Tariff.IntervalNoParkingFeeTo" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                              <v-flex xs12>
                              <v-text-field :label="t('$vuetify.tarrifSetup.dayMax')" type="number" v-model="evseGroupsModel[4].Tariff.DayMax" :disabled="!evseGroupsModel[4].isEditing" required></v-text-field>
                              </v-flex>
                          </v-layout>
                          </div>
                      </v-flex>
                    </v-flex>
                </v-layout>
            </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
// import SubscriptionType from '../../services/SubscriptionType'
/* eslint-disable */

import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'

  export default {
    data: () => ({
      menu : false,
      date : new Date().toISOString().substr(0, 10),
      exist : false,
      startDate: '',
      menuRef: false,
      calcTpe: [{'label': 'Calculated', 'value': 1}, {'label': 'UpperLevel', 'value': 2}, {'label': 'Provided', 'value': 3}],
      dialog: false,
      relationTypeTab: [
        {'label': 'Partner - CPO', 'value': 1},
        {'label': 'VCPO - CPO', 'value': 1},
        {'label': 'CPO - MSP', 'value': 3},
        {'label': 'MSP-VMSP', 'value': 4}
      ],
      exist: false,
      evseGroupsModel: [
        {
          'EvType': 'AC',
          'Tariff': {
            'Code': '',
            'KwhFee': '',
            'RotationFee': '',
            'StartFee': '',
            'ParkingFee': '',
            'CalculationType': '1',
            'DayMax': '',
            'MinutesNoParkingFee': '',
            'IntervalNoParkingFeeFrom': '',
            'IntervalNoParkingFeeTo': '',
            'MinutesNoRotationFee': '',
            'IntervalNoRotationFeeFrom': '',
            'IntervalNoRotationFeeTo': ''
          },
          'isEditing': false
        },
        {
          'EvType': 'DC',
          'Tariff': {
            'KwhFee': '',
            'Code': '',
            'RotationFee': '',
            'StartFee': '',
            'ParkingFee': '',
            'DayMax': '',
            'CalculationType': '1',
            'MinutesNoParkingFee': '',
            'IntervalNoParkingFeeFrom': '',
            'IntervalNoParkingFeeTo': '',
            'MinutesNoRotationFee': '',
            'IntervalNoRotationFeeFrom': '',
            'IntervalNoRotationFeeTo': ''
          },
          'isEditing': false
        },
        {
          'EvType': 'BIKE',
          'Tariff': {
            'KwhFee': '',
            'Code': '',
            'RotationFee': '',
            'StartFee': '',
            'ParkingFee': '',
            'DayMax': '',
            'CalculationType': '1',
            'MinutesNoParkingFee': '',
            'IntervalNoParkingFeeFrom': '',
            'IntervalNoParkingFeeTo': '',
            'MinutesNoRotationFee': '',
            'IntervalNoRotationFeeFrom': '',
            'IntervalNoRotationFeeTo': ''
          },
          'isEditing': false
        },
        {
          'EvType': 'ROAMING',
          'Tariff': {
            'KwhFee': '',
            'Code': '',
            'RotationFee': '',
            'StartFee': '',
            'ParkingFee': '',
            'DayMax': '',
            'CalculationType': '1',
            'MinutesNoParkingFee': '',
            'IntervalNoParkingFeeFrom': '',
            'IntervalNoParkingFeeTo': '',
            'MinutesNoRotationFee': '',
            'IntervalNoRotationFeeFrom': '',
            'IntervalNoRotationFeeTo': ''
          },
          'isEditing': false
        },
        {
          'EvType': 'PRIVATE',
          'Tariff': {
            'KwhFee': '',
            'Code': '',
            'RotationFee': '',
            'StartFee': '',
            'ParkingFee': '',
            'DayMax': '',
            'CalculationType': '1',
            'MinutesNoParkingFee': '',
            'IntervalNoParkingFeeFrom': '',
            'IntervalNoParkingFeeTo': '',
            'MinutesNoRotationFee': '',
            'IntervalNoRotationFeeFrom': '',
            'IntervalNoRotationFeeTo': ''
          },
          'isEditing': false
        }
      ],
      code : '',
      subscriptionType: {
        'Code': '',
        'Description': '',
        'AdminState': '',
        'StartDate': '',
        'EndDate': '',
        'RelationType': 3,
        'OneTimeFee': '',
        'MonthlyFee': '',
        'QuarterlyFee': '',
        'YearlyFee': '',
        'Uci': '',
        'CalculationType': '',
        'SubscriptionTypeEVSEGroups': []
      },
      evGroup : [],
      relationId : 0
    }),
    mounted () {
      this.checkUserInfo()
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${year}-${month}-${day}`
      },
      async checkUserInfo () {
        try {
          const result = await new UserApi().userInfo()
          // console.log("data user")
          // console.log(result)
          this.relationId = result.data.data.Relation.Id
        } catch (error) {
          console.error(error)
        }
      },
      async save (code) {
        this.dialog = false
        this.subscriptionType.SubscriptionTypeEVSEGroups = []
        if (!(this.isModule)) {
          this.subscriptionType.RelationType = this.relationType.type
        }
        for (let index = 0; index < this.evseGroupsModel.length; index++) {
          const elm = this.evseGroupsModel[index].Tariff;
          let state = false
          var origKey = ''
          for (origKey in elm) {
            if (elm[origKey] !== '' && origKey !== 'CalculationType') {
              state = true
            }
          }
          // this.evseGroupsModel[index].Tariff.Code = this.Code + "_" + this.evseGroupsModel[index].EvType
          if (state) {
            this.evGroup.push(this.evseGroupsModel[index])
          }
        }
        // console.log(this.evGroup)
        this.subscriptionType.SubscriptionTypeEVSEGroups = this.evGroup
        this.subscriptionType.create = true
        this.subscriptionType.StartDate = this.formatDate(this.startDate)
        // console.log('SAVE : ')
        // console.log(this.subscriptionType)
        try {
          // console.log(this.relationId)
          const data = await new RelationApi().addSubscriptionType(this.subscriptionType, this.relationId)
          // console.log(data)
        } catch (error) {
          console.log(error)
        }
        this.$emit('save', this.subscriptionType)
      },
      async check () {
        /* let data = await SubscriptionType.show(this.code)
        if (!this.isEmpty(data)) {
            this.exist = true
        } else {
            this.subscriptionType.Code = this.code
            this.exist = false
        } */
      },
      isEmpty (obj) {
        // console.log(obj)
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false
        }
        return true
      }
    },
    watch: {
      code (val) {
        /* if (val.length > 3) {
          this.check()
        } */
        this.subscriptionType.Code = this.code
      }
    },
    computed: {
      getCodeColor () {
        return this.exist ? 'red' : 'blue'
      }
    },
    props: {
      isModule: {
        type: Boolean,
        default: false
      },
      relationType: {
        type: Object,
        default: () => ({
          'id': 1,
          'val': '',
          'link': '',
          'linkCreate': '',
          'type': 0
        })
      },
      isCompensation: {
        type: Boolean,
        default: false
      }
    }
  }
</script>