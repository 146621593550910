




import RelationContactApi from '@/services/RelationContactApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import StepThreeNewLocation from './StepThreeNewLocation.vue'
import LocationApi from '@/services/LocationApi'
import MapDTO from '../MapDTO.vue'

@Component({
  components: {
    StepThreeNewLocation,
    MapDTO
  }
})
export default class StepThree extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  loading = false
  state = 0
  error: string | null = null
  icon = ''
  color = 'primary'
  selected = 2
  relationContacts: Array<any> = []
  existingLocations: Array<any> = []

  tmpLocation = {
    latitude: 0,
    longitude: 0
  }

  mounted () {
    this.initPosition()
    this.fetchExistingLocations()
  }

  initPosition () {
    this.formSynced.gps.latitude = 0
    this.formSynced.gps.longitude = 0
    this.tmpLocation.latitude = 0
    this.tmpLocation.longitude = 0
    // this.geolocation()
  }

  geolocation () {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position)
      this.formSynced.gps = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
    })
  }

  /* getAddress (place: any) {
    console.log(place)
    this.formSynced.gps.latitude = place.geometry.location.lat()
    this.formSynced.gps.longitude = place.geometry.location.lng()
    this.formSynced.location.street = place.address_components[0].long_name
    this.formSynced.location.city = place.address_components[1].long_name
    this.formSynced.location.postalcode = place.address_components[5].long_name
    this.formSynced.location.country = place.address_components[4].short_name
  } */

  getAddress (place: any) {
    console.log(place)
    console.log(place.geometry.location.lat())
    this.formSynced.gps.latitude = place.geometry.location.lat()
    this.formSynced.gps.longitude = place.geometry.location.lng()
    // this.updLoc.Latitude = place.geometry.location.lat()
    // this.updLoc.Longitude = place.geometry.location.lng()
    console.log('Get Address')
    /* this.cp.Location.Street = place.address_components[0].long_name
    this.cp.Location.City = place.address_components[1].long_name
    this.cp.Location.ZipCode = place.address_components[5].long_name */
    // this.cp.Location.location.country = place.address_components[4].short_name
  }

  updateCoordinates (location: any) {
    // this.formSynced.gps.latitude = location.latLng.lat()
    // this.formSynced.gps.longitude = location.latLng.lng()
    this.tmpLocation.latitude = location.latLng.lat()
    this.tmpLocation.longitude = location.latLng.lng()
    console.log('updates coordinates')
  }

  get select () {
    return [
      { key: 1, label: this.t('$vuetify.chargepoint.step3.noLocation') },
      { key: 2, label: this.t('$vuetify.chargepoint.step3.newLocation') },
      { key: 3, label: this.t('$vuetify.chargepoint.step3.existingLocation') }
    ]
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  async fetchExistingLocations () {
    const dt = await new LocationApi().getLocations()
    this.existingLocations = dt
  }

  back () {
    // this.$store.commit('chargerInstallationState/setRelationId', '')
    // this.$store.commit('chargerInstallationState/setRelationContactId', '')
    this.$emit('back')
  }

  cancel () {
    this.$emit('cancel')
  }

  next () {
    this.formSynced.gps.latitude = this.tmpLocation.latitude
    this.formSynced.gps.longitude = this.tmpLocation.longitude
    this.$emit('next')
  }

  @Watch('selected')
  selectedOnChanged (val: any, oldVal: any) {
    this.formSynced.location = {}
    this.error = ''
  }
}
