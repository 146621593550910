


















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import ChargePointApi from '@/services/ChargePointApi'
import moment from 'moment'
// import TariffDialog from './TariffDialog.vue'
import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'
import MapApi from '@/services/MapApi'
import { mapGetters } from 'vuex'
import ChargePointPlacementApi, { IPlacementCreate } from '@/services/ChargePointPlacementApi'

@Component({
  /* components: {
    TariffDialog
  }, */
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(String) readonly chargepointId: any
  @PropSync('form', { type: Object }) formSynced!: any

  dialog = false
  step = 0
  isMapFree = false
  isMapPaid = false
  isReimbursementPlug = false
  radioGroupGuestCharging = 1
  errMsg = ''

  payload = {
    toggle: 0, // Enable or disable guestcharging
    published: 0, // Make charge public (will show on map, ...)
    reimburse: 0, // Reimburse or no reimburse?
    tariffid: '' // Tariff for reimbursement
  }

  headersTokensGroup = [
    { text: 'Charge card', value: 'group' },
    { text: 'Reimbursement', value: 'action' },
    { text: '', value: 'action' },
    { text: '', value: 'action' }
  ]

  tokensGroup = []
  tokenList: any = []
  relationId = 0
  isChargeCardAccess = false
  isDuplicate = false
  isEditNameGroup = false
  isChargeCardReimbursement = false
  isReimbursement = false
  isCreateTariff = false
  newToken = null

  itemsTariff = [
    { Description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'), Code: 'SUB-PARTNER-UPPER-NOUPLIFT' }
  ]

  loadTarif = false
  tariffid = {
    ID: 0
  }

  next () {
    this.$emit('save')
  }

  back () {
    this.$emit('back')
  }

  cancel () {
    this.$emit('cancel')
  }

  get contractData (): any {
    // @ts-ignore
    return this.contractDTA
  }

  get userData (): any {
    // @ts-ignore
    return this.userDTA
  }

  async save () {
    // console.log(this.formSynced)
    /* console.log('[DEBUG] -- Save')
    console.log(this.cp.Location)
    const payload = {
      id: this.cp.Location.Id,
      name: this.cp.Location.Label,
      // [TODO] - name: this.cp.Location.Country,
      description: this.cp.Location.Description,
      landownerid: this.cp.Location.LandOwnerId,
      street: this.cp.Location.Street,
      housenr: this.cp.Location.HouseNumber,
      city: this.cp.Location.City,
      postalcode: this.cp.Location.ZipCode,
      country: this.cp.Location.Country,
      lng: this.cp.Location.Latitude,
      lat: this.cp.Location.Longitude,
      phone: this.cp.Location.Phone
    }
    try {
      const result: any = await new MapApi().createUpdate(payload)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
    console.log('[DEBUG SAVE GUEST CHARGING]')
    console.log(this.payload)
    // disabled
    if (this.radioGroupGuestCharging === 0) {
      console.log('DISABLED')
      this.payload = {
        toggle: 0,
        published: 0,
        reimburse: 0,
        tariffid: ''
      }
    } else if (this.radioGroupGuestCharging === 1) {
      console.log('FREE')
      this.payload = {
        toggle: 1,
        published: this.isMapFree ? 1 : 0,
        reimburse: 0,
        tariffid: ''
      }
    } else {
      console.log('PAID')
      this.payload = {
        toggle: 1,
        published: this.isMapPaid ? 1 : 0,
        reimburse: 1,
        tariffid: String(this.tariffid.ID)
      }
    }

    try {
      const result: any = await new ChargePointApi().guestCharging(this.payload, this.chargepointId)
      console.log(result)
      // this.relationId = result.data.data.Relation.Id
    } catch (error) {
      console.error(error)
    }
    this.dialog = false */
  }

  updateCoordinates (location: any) {
    this.cp.Location.Latitude = location.latLng.lat()
    this.cp.Location.Longitude = location.latLng.lng()
  }

  startConfiguration () {
    // this.getListTariff()
    // this.formSynced.guestcharge.isGuestCharge = true
  }

  cancelChargeCardAccess () {
    this.dialog = false
    this.step = 0
    this.isChargeCardAccess = false
    this.isChargeCardReimbursement = false
    this.isReimbursement = false
    this.isCreateTariff = false
  }

  async checkUserInfo () {
    try {
      const result: any = await new UserApi().userInfo()
      this.relationId = result.data.data.Relation.Id
    } catch (error) {
      console.error(error)
    }
  }
}
