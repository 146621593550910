import { AxiosRequestConfig } from 'axios'
import BaseApi from './BaseApi'

interface IPlacementCreate {
  chargepointserialnumber?: string
  chargertype?: string
  relationid?: string | number
  logicalgrpid?: string | number
  loc1?: string | number
  loc2?: string | number
  location?: {
    relationid?: string | number
    id?: string | number
    name?: string
    street?: string
    country?: string
    housenumber?: string
    city?: string
    postalcode?: string
    description?: string
    latitude?: string
    longitude?: string
    cplogicalgrpid?: string
    cplogicalgrpdescr?: string
    groupingparam1?: string
    groupingparam2?: string
  }
}

class ChargePointPlacementApi extends BaseApi {
  constructor (type = 0) {
    super('ChargePointPlacement', type)
  }

  static async checkSync (placementId: string | number) {
    return new Promise(async (resolve, reject) => {
      try {
        const dt = await new this(0).getApi(`v2/chargepointplacement/BCO/log/placement/${placementId}`)
        resolve(dt)
      } catch (error) {
        reject(error)
      }
    })
  }

  static async createPlacement (data: IPlacementCreate, debug = 0) {
    // console.log('DATA')
    // console.log(data)
    return new Promise(async (resolve, reject) => {
      let dbg = ''
      if (debug === 1) {
        dbg = '?debug=1'
      }
      try {
        const opts: AxiosRequestConfig = {
          url: 'v3/chargeplacement/BCO/create',
          method: 'post',
          data: data
        }
        const dt = await new this(0).postApi(opts) as any
        // console.log(dt)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }
}

export { ChargePointPlacementApi, IPlacementCreate }
export default ChargePointPlacementApi
