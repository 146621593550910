










































































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync, Prop } from 'vue-property-decorator'
import SubscriptionTypeApi from '@/services/SubscriptionTypeApi'

@Component
export default class AutocompleteTokenList extends Vue {
  @PropSync('selected', { type: String }) selectedSynced!: any
  @Prop({ type: Boolean, default: false }) readonly isPrivate: any

  tokens: Array<any> = []
  loaded = false
  loading = false
  showNewTarif = false
  search = ''
  tariff = {
    Fee: '',
    Description: ''
  }

  mounted () {
    this.$nextTick(() => {
      if (this.selectedSynced) {
        this.getTokens()
      }
    })
  }

  doLoading () {
    if (!this.loaded) {
      this.getTokens()
    }
  }

  async getTokens () {
    // console.log('get tokens : ')
    // console.log('private state : ' + this.isPrivate)
    this.loading = true
    const dt = await new SubscriptionTypeApi().getFilteredSubscriptions(1, 1) as any
    if (!this.isPrivate) {
      // console.log('BEFORE TOKEN BEFOR WHILE')
      // console.log(dt.data)
      for (let index = 0; index < dt.data.length; index++) {
        // console.log('IN TOKEN BEFOR WHILE')
        const tariffTab = dt.data[index].Tariffs
        let found = false
        // console.log(tariffTab)
        for (let j = 0; j < tariffTab.length && !found; j++) {
          const element = tariffTab[j]
          // console.log(element)
          // console.log(element.code)
          // console.log(element.code.indexOf('PRIVATE'))
          if (element.code.indexOf('PRIVATE') !== -1) {
            found = true
          }
        }
        if (found) {
          // dt.data[index].Code = dt.data[index].Code + '_PRIVATE'
          dt.data[index].Code = dt.data[index].Code + '_PRIVATE'
        } else {
          // dt.data[index].Code = dt.data[index].Code + '_PRIVATE'
          dt.data[index].Code = dt.data[index].Code + '_AC'
        }
        // console.log(dt.data[index])
      }
    }
    this.loading = false
    this.loaded = true
    this.tokens = dt.data
    // console.log('DEBUG RSLT :')
    // console.log(this.tokens)
    // console.log('-----------')
  }

  async newPresetTarif () {
    this.showNewTarif = true
  }

  async savePresetTarif () {
    try {
      this.loading = true
      const payload = this.tariff
      payload.Fee = payload.Fee.replace(',', '.')
      const dt = await new SubscriptionTypeApi().addCustomFee(payload) as any
      // console.log(dt)
      this.loading = false
      this.loaded = true
      this.getTokens()
      this.selectedSynced = dt.data.Code
      this.showNewTarif = false
      this.tariff = {
        Fee: '',
        Description: ''
      }
    } catch (error) {
      console.log(error)
    }
  }
}
