











































































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import TokenInput from './TokenInput.vue'

@Component({
  components: {
    TokenInput
  },
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @PropSync('tokenForm', { type: Array }) tokenFormSync!: any

  isChecking = false
  isChecked = false
  tokensTab: any = []
  isReset = false
  /* tokenForm = [
    {
        printednumber : '',
        description : ''
    }
  ] */

  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  errMSG = ''

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  get checkIsNext () {
    // check if there no doubles
    // check if there are no invalides
    // check if there minimum one valide
    console.log(this.tokenFormSync)
    let isValid =  this.tokenFormSync.length > 0
    for (let index = 0; index < this.tokenFormSync.length && isValid; index++) {
      console.log('DEBUG FOR EACH')
      isValid = this.tokenFormSync[index].isValidToken
      console.log(isValid)
    }

    /* if (isValid) {
      // check if double
    } */

    return isValid && this.isUnique()
  }


  addTokenInput () {
    this.tokenFormSync[this.tokenFormSync.length-1].completed = true
    this.tokenFormSync.push(
      {
        printednumber : '',
        description : '',
        relationcontactid: 0,
        subscriptiontypeid: -1,
        isValidToken: false,
        currentSub:-1,
        completed: false,
        isPublic:false
      }
    )
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.isCheck = false
    this.loading = false
  }

  clearToken (index: number){
    // remove token from tab
    const searchToken = this.tokenFormSync[index].printednumber
    const pos = this.tokensTab.indexOf(this.tokenFormSync[index].printednumber)
    let isFound = false
    // check if other token form has this number
    for (let index = 0; index < this.tokenFormSync.length && !isFound; index++) {
      const element =  this.tokenFormSync[index];
      if (this.tokenFormSync[index].printednumber === searchToken) {
        isFound = true
      }
    }
    if (!isFound) {
      this.tokensTab.splice(pos, 1)
    }
    // this.tokensTab.splice(pos, 1)
    // this.tokensTab[pos] = ""
    // remove token from form
  }


  removeToken (index: number){
    // remove token from tab
    this.tokenFormSync.splice(index, 1)
    this.tokenFormSync[this.tokenFormSync.length-1].completed = false
  }

  removeAll () {
    const size = this.tokenFormSync.length
    for (let index = 0; index < size; index++) {
      this.tokenFormSync.splice(0, 1)
    }
  }



  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1 || this.formSynced.ChargepointSerialNumber === 'PIETER') {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        if (this.formSynced.ChargepointSerialNumber === 'PIETER') {
          this.isCheck = true
          this.state = 1
          this.formSynced.isValidToken = true
        } else {
          const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
          this.cp = rslt.data
          this.state = 1
          this.formSynced.isValidToken = true
        }
      } catch (error: any) {
        this.formSynced.isValidToken = false
        this.showError(error.obj.result.message)
      }
    } else {
      this.formSynced.isValidToken = false
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  isUnique () {
    const tokenTab = []
    for (let index = 0; index < this.tokenFormSync.length; index++) {
      const element = this.tokenFormSync[index].printednumber;
      if (element !== '') {
        tokenTab.push(element)
      }
    }
    return new Set(tokenTab).size === tokenTab.length
  }

  nextStep () {
    // Check if the're no double card :
    if (this.isUnique()) {
      // this.$store.commit('chargerInstallationState/setSerialNumber', this.formSynced.ChargepointSerialNumber)
      this.$emit('next')
    }
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.removeAll()
    this.isReset = true
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  /* @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  } */
}
