



















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AutocompletePresetTokenList from '../../presets/AutocompletePresetSubscriptionList.vue'
import SubscriptionApi from '@/services/SubscriptionApi'

@Component({
  components: {
    AutocompletePresetTokenList
  },
  computed: {
    ...mapGetters({ currentStep: 'chargerInstallationState/stepName', userDT: 'user/userInfo', elm: 'chargerInstallationState/elmState'})
  }
})
export default class AddCardWithCharger extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any
  @Prop(Boolean) readonly isReimburse: any
  description = ''
  dialog = false
  isScan = true
  lastAuthorizedTab: any = []
  radios = '1'

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  customFee = ''

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  mounted () {
    this.getLastAuthorize()
  }

  async getLastAuthorize () {
    // console.log('load')
    // console.log(this.elmDTA)
    // Wat was de laatse authorize op de laadpaal? (initiele)
    try {
      // const dta: any = await new SubscriptionApi().getLastAuthorize(this.elmDTA.chargepointID)
      // console.log(this.elmDTA)
      // const dta: any = await new SubscriptionApi().getLastAuthorize(this.elmDTA.chargepointID)
      const dta: any = await new SubscriptionApi().getLastAuthorize('3046')
      // console.log('GET LAST AUTHORIZE')
      // console.log(dta)
      this.lastAuthorizedTab.push(dta)
    } catch (error) {
      console.log(error)
    }
  }

  addToken () {
    const payload = {
      Id: this.lastAuthorizedTab[(this.lastAuthorizedTab.length-1)].Id,
      TokenCode: this.lastAuthorizedTab[(this.lastAuthorizedTab.length-1)].TokenCode,
      Description: this.description
    }
    if (!this.isReimburse) {
      this.$store.commit('chargerInstallationState/addRFIDToken', payload)
    } else {
      this.$store.commit('chargerInstallationState/addRFIDReimbToken', payload)
    }
    this.cleanUp()
    this.$emit('addCard', payload)
  }

  swipeAgain () {
    this.isScan = true
    this.lastAuthorizedTab.splice((this.lastAuthorizedTab.length-1), 1)
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.addToken()
    }
  }

  cleanUp () {
    this.isScan = true
    this.description = ''
    this.formCPSN.resetValidation()
    this.formCPSN.reset()
    this.dialog = false
  }

  nextStep () {
    this.$emit('next')
  }

  cancel () {
    this.cleanUp()
    this.$emit('cancel')
  }
}
