























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'

@Component
export default class TechnicalData extends Vue {
  @Prop(Object) readonly cp: any

  rowOne = [
    { field: 'OnlineStateDateTime', label: this.t('$vuetify.chargepoint.OnlineStateDateTime') },
    { field: 'BootNotificationDateTime', label: this.t('$vuetify.chargepoint.bootNotificationTime') },
    { field: 'Model', label: this.t('$vuetify.chargepoint.Model') },
    { field: 'Vendor', label: this.t('$vuetify.chargepoint.Vendor') }
  ]

  rowTwo = [
    { field: 'FirmwareVersion', label: this.t('$vuetify.chargepoint.FirmwareVersion') },
    { field: 'ChargeBoxSN', label: this.t('$vuetify.chargepoint.ChargeBoxSN') },
    { field: 'EndpointUrl', label: this.t('$vuetify.chargepoint.EndpointUrl') },
    { field: 'MeterType', label: this.t('$vuetify.chargepoint.MeterType') }
  ]
}
