import BaseApi from './BaseApi'
import { AxiosRequestConfig } from 'axios'

export default class TokenApi extends BaseApi {
  constructor (type = 0) {
    super('Token', type)
  }

  async getBySubscriptionId (cusId: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/subid/${cusId}`
    }
    return await this.getApi(options)
  }

  async findByCusId (CusId: string | number) {
    try {
      const filter = { CusId }
      const dt = await this.filter(filter, { pagenumber: 1, pagesize: 1000 })
      return dt
    } catch (err) {
      console.log(err)
    }
  }

  async check (printedNumber: string): Promise<Record<string, any>> {
    return new Promise<Record<string, any>>(async (resolve, reject) => {
      const opts = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/verify`,
        data: {
          printednumber: printedNumber
        }
      }
      try {
        const isNotified = false
        const dt: any = await this.postApi(opts, true, isNotified)
        resolve(dt)
      } catch (error) {
        reject(error)
      }
    })
  }

  // TODO use interface of tom
  async createUpdate (relation: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/createupdate`,
      data: relation
    }
    return await this.postApi(opts)
  }

  async link (payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      data: payload
    }
    const isNotified = false
    return await this.postApi(opts, true, isNotified)
  }

  async updateTokenData (payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/update`,
      data: payload
    }
    const isNotified = false
    return await this.putApi(opts, true, isNotified)
  }

  async bluelink (payload: any) {
    const opts: AxiosRequestConfig = {
      url: `https://portal.bluecorner.be/blue/api/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async srvlink (payload: any) {
    const opts: AxiosRequestConfig = {
      url: `https://srv.octaplus.be/blue/api/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      data: payload
    }
    return await this.postApi(opts)
  }

  async getIndicators () {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/Stats`
    }
    return await this.getApi(options)
  }

  async getIndicatorType (typeIndicator: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/indicator/BCO/${typeIndicator}`
    }
    return await this.getApi(options)
  }

  async KWHMyTokens (typeIndicator: any) {
    // console.log('CALL tokens')
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/indicator/BCO/${typeIndicator}`
    }
    return await this.getApi(options)
  }
}
