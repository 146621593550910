






















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SubscriptionApi from '../../services/SubscriptionApi'
import moment from 'moment'
import AddToken from './AddToken.vue'
import TariffTabCardDetails from './TariffTabCardDetails.vue'
import GenericTablePagination from '../../components/_base/BcDataTable/GenericTablePagination.vue'
import PaginationInterface from '../../typings/PaginationInterface'
import downloadExcel, { ExcelData } from '../../functions/downloadExcel'
import { Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AddChargepointModal from '../../components/chargepoint/AddChargepointModal.vue'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import { GENERIC_FILTER_CARDS } from '@/mocks/featureFlags'
import { DataTableHeader } from 'vuetify'
import { HeadRows } from '@/typings/OptionsDataTable'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { Tokens } from '@/typings/Tokens'
import {
  RelationFilterInterface,
  RelationTypes
} from '@/typings/RelationFilterInterface'

@Component({
  components: {
    TariffTabCardDetails,
    AddToken,
    GenericTablePagination,
    AddChargepointModal
  },
  filters: {
    formatDateTime(val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    },
    formatLA(val: string) {
      return val !== undefined && val !== '' ? val : ' - '
    },
    capitalize(val: string) {
      if (!val) return ''
      val = val.toString()
      return val.charAt(0).toUpperCase() + val.slice(1)
    },
    lowerCaseTxt(val: string) {
      if (!val) return ''
      val = val.toString()
      return val.toLowerCase()
    }
  },
  computed: {
    ...mapGetters({
      userDT: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA',
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class MyTokens extends Vue {
  genericRelationFilter!: RelationFilterInterface
  dialog = false
  delimiter = ','
  isExport = false
  isTable = true
  selected: Tokens[] = []
  loading = true
  page = 1
  pageSize = 10
  current = 1
  pageCount = 0
  copyTokenTab: Tokens[] = []
  headers: DataTableHeader[] = [
    {
      text: this.t('$vuetify.myChargeCards.chargePass'),
      value: 'printednr',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeCards.driver'),
      value: 'relationcontact',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeCards.description'),
      value: 'description',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeCards.subscription'),
      value: 'subscriptiontype',
      sortable: false,
      width: '20%'
    }
  ]

  headersExcel: DataTableHeader[] = [
    {
      text: this.t('$vuetify.myChargeCards.chargePass'),
      value: 'printednr',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeCards.driver'),
      value: 'relationcontact',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeCards.description'),
      value: 'description',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeCards.subscription'),
      value: 'subscriptiontype',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeCards.state'),
      value: 'state',
      sortable: false
    }
  ]

  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  exportLoading = false
  tokensData: Tokens[] = []
  showMobileMenu = false
  search = ''

  mounted() {
    this.initPage()
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu
  }

  exportDownload(delimiter: ',' | ';') {
    const data: ExcelData = {
      headers: this.head,
      data: this.tableData,
      options: {
        name: 'Tokens',
        exportFileName: 'Charger Cards - List',
        fileExtension: 'xlsx',
        csvDelimiter: delimiter
      }
    }
    downloadExcel(data)
  }

  refresh() {
    this.notification({
      content: 'Added cards',
      color: 'success',
      timeout: 6000
    })
    this.initPage()
    this.selected = []
  }

  get tableData() {
    const rows: Array<Array<string>> = []
    this.selected = this.tokensData
    for (let i = 0; i < this.selected.length; i++) {
      const row: Array<string> = []
      row.push(`${this.selected[i].printednr}`)
      if (
        this.selected[i].relationcontact.fullname !== undefined &&
        this.selected[i].relationcontact.fullname !== ''
      ) {
        row.push(`${this.selected[i].relationcontact.fullname}`)
      } else {
        row.push('-')
      }
      let tariff = this.selected[i].description.toLowerCase()
      tariff = tariff.charAt(0).toUpperCase() + tariff.slice(1)
      row.push(`${this.selected[i].description}`)
      row.push(`${tariff}`)
      row.push(`${this.selected[i].state}`)
      rows.push(row)
    }
    return rows
  }

  get head() {
    const rtn: Array<HeadRows> = []
    for (let i = 0; i < this.headersExcel.length; i++) {
      rtn.push({
        name: `${this.headersExcel[i].text}`,
        totalsRowLabel: 'sum:',
        filterButton: true
      })
    }
    return rtn
  }

  async initPage() {
    try {
      this.loading = true
      this.search = ''
      const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/tokens',
        flag: GENERIC_FILTER_CARDS
      })
      let relationContactId
      let relationId
      if (isAllowed) {
        const selectedRelation = this.genericRelationFilter
        if (
          selectedRelation &&
          selectedRelation.Type === RelationTypes.relationContact &&
          selectedRelation.Id !== this.userDTA.data.RelationContactId
        ) {
          relationContactId = selectedRelation.Id
        } else if(selectedRelation && selectedRelation.Id !== this.userDTA.data.RelationContactId ) {
          relationId = selectedRelation.Id
        }
      }
      this.tokensData = []
      const dt = (await new SubscriptionApi().list(
        relationContactId,
        relationId
      )) as DefaultResponseInterface<Tokens[]>
      this.tokensData = dt.data
      this.copyTokenTab = dt.data
      this.loading = false
    } catch (error) {
      console.log(error)
    }
  }

  prev() {
    this.current--
  }

  next() {
    this.current++
  }

  @Watch('search')
  doSearch(val: string) {
    if (!this.isTable) {
      if (!this._.isEmpty(val)) {
        this.tokensData = this.copyTokenTab.filter(function (e: Tokens) {
          return (
            e.description.includes(val) ||
            e.code.includes(val) ||
            e.evcoid.includes(val) ||
            e.printednr.includes(val) ||
            e.relationcontact.fullname.includes(val)
          )
        })
      } else {
        this.tokensData = this.copyTokenTab
      }
    }
  }

  get userDTA (): any {
    // @ts-ignore
    return this.userDT
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged(newValue:RelationFilterInterface, oldValue: RelationFilterInterface) {
    if (newValue.Id !== this.userDTA.data.RelationContactId) {
      this.initPage()
    }
  }
}
