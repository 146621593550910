





























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import RemoteStart from './RemoteStart'
import Unlock from './Unlock'
import Time from '../../functions/Time'
import ChargePointApi, { IRemoteStop } from '@/services/ChargePointApi'
import { ChargePointUppercaseType, ConnectorInterface, DateTimeOutType, Placement, States } from '@/typings/ChargePoint'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'

@Component({
  components: {
    RemoteStart,
    Unlock
  },
  filters: {
    capitalize: function (value: string) {
      if (!value) return ''
      value = value.toString()
      value = value.toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
})
export default class Connector extends Vue {
  @Prop(Object) readonly cp!: ChargePointUppercaseType
  @Prop(Object) readonly connector!: ConnectorInterface
  @Prop(Object) readonly placement!: Placement

  stopSessionModal = false
  status = ''
  loadingStop = false

  timeSinceNow (time: string) {
    return Time.TimeSinceNow(time)
  }

  toTime (time: string, props: DateTimeOutType) {
    return Time.dateTime(time, props)
  }

  getStateLabel() {
    return this.isCPOffline ? 'Offline' :  this.connector.State
  }

  async stopSession () {
    if (this.connector.Session) {
      const data:IRemoteStop = {
        sessionid: this.connector.Session?.Id,
        tokenuid: this.connector.Session?.TokenUID
      }
      this.loadingStop = true
      const dt = await new ChargePointApi().remoteStop(data) as DefaultResponseInterface
      this.loadingStop = false
      if (dt.result.status === 'Success') {
        this.status = 'success'
        setTimeout(() => {
          this.stopSessionModal = false
        }, 5000)
      } else {
        this.status = 'error'
      }
    }
  }

  get state () {
    if (this.connector.SessionState && this.connector.SessionState.length > 0) {
      return this.connector.SessionState
    } else if (this._.isEmpty(this.connector.SessionState) && this.connector.State === 'OCCUPIED') {
      return 'PARKING'
    } else if (this._.isEmpty(this.connector.SessionState) && this._.isEmpty(this.connector.State)) {
      return 'PARKING'
    } else if (this.connector.State === 'UNKNOWN') {
      return 'ERROR'
    }
    return this.connector.State
  }

  getStateConnector(chargerState: States) {
    if (this.isCPOffline) {
      return States.OFFLINE
    }

    if (this.connector.State === States.occupied) {
      if (this._.isEmpty(this.connector.SessionState)) {
        return States.parking
      }
      return this.connector.SessionState
    }

    if (
      this._.isEmpty(this.connector.State) &&
      this._.isEmpty(this.connector.SessionState)
    ) {
      return States.parking
    }

    return this.connector.State
  }

  get isCPOffline() {
    return !(this.cp.State === States.available || this.cp.State === States.AVAILABLE)
  }
}
