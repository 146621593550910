


















































































































































































































import Component from 'vue-class-component'
import { Prop, PropSync, Ref, Watch, Vue } from 'vue-property-decorator'
import {
  isAddress,
  isHouseNumber,
  isMaxLengthCheck,
  isTexte
} from '../../functions/formRules'
import RelationContactApi from '../../services/RelationContactApi'
import { mapGetters } from 'vuex'
import { isEmail, isRequired } from '@bluecorner/shared'
import DisabledEmailField from '@/components/common/DisabledEmailField.vue'
import BcCountrySelect from '@/components/_base/BcCountrySelect'
import BcBtn from '@/components/_base/BcBtn'
import { RelationInterface } from '@/services/RelationApi'

@Component({
  components: { DisabledEmailField, BcCountrySelect, BcBtn },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  }
})
export default class NewModal extends Vue {
  @Prop(Object) readonly parent!: RelationInterface
  @Prop(Object) readonly driver: any
  @Prop(Boolean) readonly isEdit!: boolean
  @PropSync('value', { type: Boolean }) valueSynced!: boolean
  @PropSync('value', { type: Boolean }) isDriver!: boolean
  @PropSync('value', { type: Boolean }) isContactForm!: boolean
  @Ref() readonly RelationContactForm!: any
  @Ref() readonly RelationForm!: any

  type = ''
  isNewPortal = false
  isSaving = false
  valid = true
  fieldsRelationContact: any = {
    relationid: '',
    firstname: '',
    lastname: '',
    telephone: '',
    email: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    remarkinternal: '',
    remarkpublic: '',
    remark: '',
    isActive: null,
    hasportal: null,
    isentitymanager: null
  }

  required: Array<any> = [isRequired('This field is required')]

  rulesEmail: Array<any> = [
    isRequired('This field is required'),
    isMaxLengthCheck('Maximum no. of characters allowed is 240', 240),
    isEmail('Invalid Email address')
  ]

  rulesPhone: Array<any> = [isRequired('This field is required')]

  rulesName: Array<any> = [
    isTexte('Invalid'),
    isMaxLengthCheck('Maximum no. of characters allowed is 100', 100),
    isRequired('This field is required')
  ]

  rulesAddress: Array<any> = [isAddress('This field is required')]

  rulesHouseNumber: Array<any> = [isHouseNumber('This field is required')]

  cancel() {
    this.fieldsRelationContact = {
      relationid: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      remarkinternal: '',
      remarkpublic: '',
      remark: '',
      isActive: null,
      hasportal: null,
      isentitymanager: null
    }
    this.RelationContactForm.reset()
    this.RelationContactForm.resetValidation()
    this.valueSynced = false
  }

  async update() {
    if (this.RelationContactForm.validate()) {
      this.isSaving = true
      // Init relation ID
      const payload: any = {}
      const keys = Object.keys(this.fieldsRelationContact)
      keys.forEach((e) => {
        payload[e.toLowerCase()] = this.fieldsRelationContact[e]
      })
      payload.relationid = this.driver.relationid
      try {
        // Update Driver
        const result = (await new RelationContactApi().createUpdate(
          payload
        )) as any
        const dta = { id: result.data.Id }

        // Create Portal if driver doesn't exists
        if (this.driver.HasPortal) {
          if (this.fieldsRelationContact.isActive) {
            await new RelationContactApi().activateUser(dta.id)
          } else {
            await new RelationContactApi().deactivateUser(dta.id)
          }
        } else {
          if (this.fieldsRelationContact.isActive) {
            await new RelationContactApi().createPortal(dta)
          }
        }

        // Update fleet manager
        if (this.fieldsRelationContact.isentitymanager) {
          await new RelationContactApi().assignFleet(dta.id)
        } else {
          if (this.driver.IsEntityManager) {
            await new RelationContactApi().removeFleet(dta.id)
          }
        }
        this.notification({
          content: 'Updated',
          color: 'success',
          position: 'right-top'
        })
      } catch (e) {
        console.log(e)
      }
      this.isSaving = false
      this.valueSynced = false
      this.cancel()
      this.$emit('refresh')
    }
  }

  async save() {
    // Init relation ID
    const payload: any = {}
    const keys = Object.keys(this.fieldsRelationContact)
    keys.forEach((e) => {
      payload[e.toLowerCase()] = this.fieldsRelationContact[e]
    })
    payload.relationid = this.parent.Id
    // END
    if (this.RelationContactForm.validate()) {
      this.isSaving = true
      // Init relation ID
      const payload: any = {}
      const keys = Object.keys(this.fieldsRelationContact)
      keys.forEach((e) => {
        payload[e.toLowerCase()] = this.fieldsRelationContact[e]
      })
      payload.relationid = this.parent.Id
      try {
        // Create Driver
        const result = (await new RelationContactApi().createUpdate(
          payload
        )) as any
        const dta = { id: result.data.Id }

        // Create Portal
        if (this.fieldsRelationContact.isActive) {
          await new RelationContactApi().createPortal(dta)
        }

        // Create fleet manager
        if (this.fieldsRelationContact.isentitymanager) {
          await new RelationContactApi().assignFleet(dta.id)
        }
        this.isSaving = false
        this.valueSynced = false
        this.notification({
          content: 'Success',
          color: 'success',
          timeout: -1
        })
        this.$emit('refresh')
        this.cancel()
      } catch (e: any) {
        if (e.errorcode !== '') {
          this.notification({
            content: e.errorcode,
            timeout: -1,
            color: 'error'
          })
        } else {
          this.notification({
            content: 'UNEXPECTED',
            timeout: -1,
            color: 'error'
          })
        }
      }
      this.isSaving = false
    }
  }

  @Watch('valueSynced')
  valueSyncedOnChanged(val: boolean, oldVal: boolean) {
    if (oldVal && !val) {
      this.type = ''
      this.fieldsRelationContact = {
        relationid: '',
        firstname: '',
        lastname: '',
        telephone: '',
        email: '',
        street: '',
        housenumber: '',
        postalcode: '',
        city: '',
        remarkinternal: '',
        remarkpublic: '',
        isActive: null,
        hasportal: null,
        isentitymanager: null
      }
    }
  }

  @Watch('driver')
  driverOnChanged(val: boolean) {
    this.isNewPortal = false
    this.fieldsRelationContact = {
      id: this.driver.Id,
      relationid: this.driver.RelationId,
      firstname: this.driver.FirstName,
      lastname: this.driver.LastName,
      telephone: this.driver.Telephone,
      email: this.driver.Email,
      street: this.driver.Street,
      housenumber: this.driver.HouseNumber,
      postalcode: this.driver.PostalCode,
      city: this.driver.City,
      country: this.driver.Country,
      hasportal: this.driver.HasPortal,
      isentitymanager: this.driver.IsEntityManager,
      isActive: this.driver.IsActive
    }
  }

  @Watch('isDriver')
  isDriverOnChanged(val: boolean) {
    if (val && this.driver) {
      this.isNewPortal = false
      this.fieldsRelationContact = {
        id: this.driver.Id,
        relationid: this.driver.RelationId,
        firstname: this.driver.FirstName,
        lastname: this.driver.LastName,
        telephone: this.driver.Telephone,
        email: this.driver.Email,
        street: this.driver.Street,
        housenumber: this.driver.HouseNumber,
        postalcode: this.driver.PostalCode,
        city: this.driver.City,
        country: this.driver.Country,
        hasportal: this.driver.HasPortal,
        isentitymanager: this.driver.IsEntityManager,
        isActive: this.driver.IsActive
      }
    }
  }

  @Watch('fieldsRelationContact.isentitymanager', { deep: true })
  isEntityManagerOnChanged(val: string) {
    if (val) {
      this.fieldsRelationContact.isActive = val
      this.fieldsRelationContact.hasportal = val ? 1 : 0
      this.fieldsRelationContact.isentitymanager = true
    } else {
      this.fieldsRelationContact.isentitymanager = false
    }
  }
}
