






























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import moment from 'moment'
import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'
import ChargeCardAccessStep from '../ChargeCardAccessStep.vue'
import ChargePointPlacementApi, { IPlacementCreate } from '@/services/ChargePointPlacementApi'

@Component({
  components: {
    ChargeCardAccessStep
  },
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  }
})
export default class ChargepointCard extends Vue {
  @Prop(String) readonly chargepointId: any
  @PropSync('form', { type: Object }) formSynced!: any
  @Prop(Object) readonly usrDTA: any

  dialog = false
  stepCard = 0
  chargepointIdNew = 3046
  absolute = true
  overlay = false
  isShowDelete = false
  isShowDeleteGroup = false
  overlayGroup = false
  headersTokensGroup = []
  payloadDl = {
    item: { Code: '' },
    groupK: '',
    token: { name: '' }
  }

  ex11= ['red', 'indigo', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error', 'red darken-3', 'indigo darken-3', 'orange darken-3']

  payloadDlGrp = {
    name: '',
    code: ''
  }

  curGroup = {
    Code: '',
    Description: '',
    TokenWhiteListList: []
  }

  isReimbursementPlug = false

  itemBanks: any = []
  tariffsTab = []
  tokensGroup = []
  tokenList: any = []
  relationId = 0
  isChargeCardAccess = false
  isDuplicate = false
  isEditNameGroup = false
  isChargeCardReimbursement = false
  isReimbursement = false
  isCreateTariff = false
  newToken = null

  errMsg = ''

  payload = {
    id: '',
    code: '',
    groupName: '',
    tokenWhiteListList: []
  }

  mounted () {
    // this.checkUserInfo()
  }

  next () {
    this.$emit('next')
  }

  back () {
    this.$emit('back')
  }

  cancel () {
    this.$emit('cancel')
  }

  async validate () {
    // console.log('[DEBUG] START CREATE PLACEMENT')
    const out: IPlacementCreate = {
      chargepointserialnumber: this.formSynced.ChargepointSerialNumber,
      relationid: this.formSynced.relationId,
      chargertype: this.formSynced.chargerType,
      location: {
        street: this.formSynced.location.street,
        city: this.formSynced.location.city,
        name: this.formSynced.location.name,
        description: this.formSynced.location.description,
        housenumber: this.formSynced.location.housenumber,
        postalcode: this.formSynced.location.postalcode,
        latitude: this.formSynced.gps.latitude,
        longitude: this.formSynced.gps.longitude,
        country: this.formSynced.location.country
      }
    }
    try {
      const data: any = await ChargePointPlacementApi.createPlacement(out, 0)
      this.chargepointIdNew = data.data.chargePointPlacementId
      // console.log('[DEBUG] CREATED PLACEMENT : ')
    } catch (error: any) {
      this.errMsg = error.message
      // console.log(error.message)
    }
  }

  async checkUserInfo () {
    try {
      const result: any = await new UserApi().userInfo()
      this.relationId = result.data.data.Relation.Id
      // this.whiteListGroup()
      // this.getListTariff()
      // this.getGroup()
    } catch (error) {
      console.error(error)
    }
  }
}
