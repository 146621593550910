

































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import RelationApi from '../../../services/RelationApi'

@Component({
  computed: {
    ...mapGetters({
      currentStep: 'chargerInstallationState/stepName',
      elm: 'chargerInstallationState/elmState'
    })
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any

  // form
  isFormValid = true

  // form error
  error = false
  errorMessages = ''

  errorProvider = false
  errorProviderMessages = ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  mounted() {
    this.getTree()
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  reimburser = null
  isLoadingTree = false
  items: Array<any> = []

  get errorState() {
    if (this.error) {
      return true
    }
    return false
  }

  next() {
    // RFID
    // Check if plug & charge
    if (this.elmDTA.isPlugCharge) {
      this.$store.commit(
        'chargerInstallationState/setPlugChargeReimburser',
        this.reimburser
      )
    } else {
      this.$store.commit(
        'chargerInstallationState/setWLReimburser',
        this.reimburser
      )
    }
    this.$store.commit('chargerInstallationState/setStep', 'StepUsageReimburse')
  }

  get elmDTA(): any {
    // @ts-ignore
    return this.elm
  }

  normalizer(node: any) {
    return {
      id: node.id,
      label: node.description,
      children: node.children
    }
  }

  async getTree() {
    this.isLoadingTree = true
    const dt = (await new RelationApi(0).relationAllTree(true, '')) as any
    this.isLoadingTree = false
    this.items = [this.formatTree(dt.data, true)]
  }

  formatTree(object: any, isRoot = false) {
    if (isRoot) {
      const obj = object.parent
      if (obj.children) {
        delete object.parent
        obj.children = []
        const keys = Object.keys(object)
        for (let i = 0; i < keys.length; i++) {
          obj.children = obj.children.concat(object[keys[i]])
        }
      }
      return obj
    } else {
      let array: Array<any> = []
      delete object.parent
      const keys = Object.keys(object)
      for (let i = 0; i < keys.length; i++) {
        array = array.concat(object[keys[i]])
      }
      return array
    }
  }

  reset() {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  getColor(connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate() {
    this.isCheck = true
    this.loading = true
    const res = (await new ChargePointApi().verifyChargePoint(
      this.formSynced.ChargepointSerialNumber
    )) as any
    if (res.Verified === 1) {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        const rslt = (await new ChargePointApi().minimumDetails(
          this.formSynced.chargepointid
        )) as any
        this.cp = rslt.data
        this.state = 1
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError(msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit() {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp() {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep() {
    this.$emit('next')
  }

  cancel() {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors() {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged(val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged(val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
