







































































































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import BcCardSettings from '@/components/_base/BcCardSettings'

@Component({
  computed: {
    ...mapGetters({
      elm: 'chargerInstallationState/elmState',
      userDT: 'user/userInfo'
    })
  },
  components: {
    BcCardSettings
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any

  // form
  isFormValid = true
  cpID = ''
  // form error
  error = false
  errorMessages = ''

  errorProvider = false
  errorProviderMessages = ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  get errorState() {
    if (this.error) {
      return true
    }
    return false
  }

  reset() {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
    this.formCPSN.resetValidation()
  }

  getColor(connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate() {
    this.isCheck = true
    this.loading = true
    try {
      const res = (await new ChargePointApi().verifyChargePoint(
        this.formSynced.ChargepointSerialNumber
      )) as any
      this.cpID = res.ChargePointId
      if (
        res.Verified === 1 ||
        this.formSynced.ChargepointSerialNumber === 'PIETER'
      ) {
        this.formSynced.chargepointid = res.ChargePointId
        try {
          if (this.formSynced.ChargepointSerialNumber === 'PIETER') {
            this.isCheck = true
            this.state = 1
          } else {
            const rslt = (await new ChargePointApi().minimumDetails(
              this.formSynced.chargepointid
            )) as any
            this.cp = rslt.data
            this.state = 1
          }
        } catch (error: any) {
          this.loading = false
          this.showError(error.obj.result.message)
        }
      } else {
        this.loading = false
        this.showError('Invalid serial number')
      }
      this.loading = false
    } catch (error) {
      this.loading = false
      this.showError('Invalid serial number')
    }
  }

  showError(msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit() {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp() {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep() {
    this.formSynced.chargepointID = this.cpID
    this.$store.commit(
      'chargerInstallationState/setSerialNumber',
      this.formSynced.ChargepointSerialNumber
    )
    this.$store.commit(
      'chargerInstallationState/setChargepointID',
      this.formSynced.chargepointID
    )
    this.$emit('next')
  }

  cancel() {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors() {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged(val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged(val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }

  get elmDTA(): any {
    // @ts-ignore
    return this.elm
  }
}
