
































































































































/* eslint-disable */
/* global google:true */

import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Ref, Emit, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA', elm: 'chargerInstallationState/elmState' })
  }
})
export default class ChargepointCard extends Vue {
  @PropSync('mapDTA', { type: Object }) tmpObj!: any
  @Ref() readonly form!: any

  isFormValid = true

  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  get userData (): any {
    // @ts-ignore
    return this.userDTA
  }

  mounted () {
    this.loadMap()
  }

  loadMap () {
    const elm = this.$refs.mapRef
    const vm = this
    // @ts-ignore
    elm.$mapPromise.then((map) => {
      this.initMap()
    })
  }

  initMap () {
    // @ts-ignore
    const geocoder = new google.maps.Geocoder()
    let val: any = null
    if (this.tmpObj.Location.HouseNumber) {
      val = this.tmpObj.Location.HouseNumber + ', ' + this.tmpObj.Location.Street + ', ' + this.tmpObj.Location.City + ', ' + this.tmpObj.Location.PostalCode + ', ' + this.tmpObj.Location.Country
    }

    geocoder.geocode({ address: val }, (results: any, status: any) => {
      if (status === "OK") {
        this.tmpObj.Location.Latitude = results[0].geometry.location.lat()
        this.tmpObj.Location.Longitude = results[0].geometry.location.lng()
      }
    })
  }

  backWiz () {
    this.tmpObj.Location = {
      id: '',
      Label: '',
      Description: '',
      Street: '',
      HouseNumber: '',
      City: '',
      ZipCode: '',
      Country: '',
      Latitude: 0,
      Longitude: 0
    }
    this.$store.commit('chargerInstallationState/setLocation', this.tmpObj.Location)
    this.$emit('back')
  }

  cancelWiz () {
    this.$emit('cancel')
  }

  nextWiz () {
    if (this.form.validate()) {
      this.$store.commit('chargerInstallationState/setLocation', this.tmpObj.Location)
      this.$emit('next')
    }
  }

  updateCoordinates (location: any) {
    this.tmpObj.Location.Latitude = location.latLng.lat()
    this.tmpObj.Location.Longitude = location.latLng.lng()
  }

  getAddress (place: any) {
    this.tmpObj.Location.Latitude = place.geometry.location.lat()
    this.tmpObj.Location.Longitude = place.geometry.location.lng()
    this.fillInAddress(place)
  }

  fillInAddress (place: any) {
    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          this.tmpObj.Location.HouseNumber = `${component.long_name}`
          break
        }

        case 'route': {
          this.tmpObj.Location.Street = component.short_name
          break
        }

        case 'postal_code': {
          this.tmpObj.Location.ZipCode = `${component.long_name}`
          break
        }

        case 'locality':
          this.tmpObj.Location.City = component.long_name
          break

        case 'country':
          this.tmpObj.Location.Country = component.short_name
          break
      }
    }
  }

  @Watch('mapDTA.Location', { deep: true })
  onTmpObjChange (val: any, oldVal: any) {
    // @ts-ignore
    const geocoder = new google.maps.Geocoder()
    if (this.tmpObj.Location.HouseNumber) {
            val = this.tmpObj.Location.HouseNumber + ', ' + this.tmpObj.Location.Street + ', ' + this.tmpObj.Location.City + ', ' + this.tmpObj.Location.PostalCode + ', ' + this.tmpObj.Location.Country

    }
    geocoder.geocode({ address: val }, (results: any, status: any) => {
      if (status === "OK") {
        this.tmpObj.Location.Latitude = results[0].geometry.location.lat()
        this.tmpObj.Location.Longitude = results[0].geometry.location.lng()
      }
    })
  }

  @Watch('tmpObj.Location', { deep: true })
  onTmpAllObjChange (val: any, oldVal: any) {
    // @ts-ignore
    const geocoder = new google.maps.Geocoder()
    if (this.tmpObj.Location.HouseNumber) {
            val = this.tmpObj.Location.HouseNumber + ', ' + this.tmpObj.Location.Street + ', ' + this.tmpObj.Location.City + ', ' + this.tmpObj.Location.PostalCode + ', ' + this.tmpObj.Location.Country

    }
    var request = {
    address: val,
      componentRestrictions: {
        country: 'BE'
      }
    }
    geocoder.geocode(request, (results: any, status: any) => {
      if (status === "OK") {
        this.tmpObj.Location.Latitude = results[0].geometry.location.lat()
        this.tmpObj.Location.Longitude = results[0].geometry.location.lng()
      }
    })
  }
}
