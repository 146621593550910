



































































import ChargePointApi from '@/services/ChargePointApi'
import SubscriptionApi from '@/services/SubscriptionApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ currentStep: 'chargerInstallationState/stepName', userDT: 'user/userInfo',  elm: 'chargerInstallationState/elmState'})
  },
  filters: {
    toAccess (val :boolean) {
      return val ? 'Public' : 'Private'
    }
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  customFeeDescription = ''
  tariffsCtn:any = 0
  mounted () {
    this.getSub()
  }

  async getSub () {
    try {
      let rslt:any = ''
      if (this.elmDTA.guestcharge.customfee !== '' && this.elmDTA.isGuestCharge) {
        rslt = await new SubscriptionApi().getSubscription(this.elmDTA.guestcharge.customfee) as any
        this.customFeeDescription = rslt.data.Description
        this.tariffsCtn = rslt.data.Tariffs[0].kwhfee
      } else {
        if (this.elmDTA.plugcharge.customfee !== '' && this.elmDTA.isPlugCharge) {
          rslt = await new SubscriptionApi().getSubscription(this.elmDTA.plugcharge.customfee) as any
          this.customFeeDescription = rslt.data.Description
          this.tariffsCtn = rslt.data.Tariffs[0].kwhfee
        } else {
          if (this.elmDTA.isRFID && this.elmDTA.accessCard.reimbsubscriptiontypeid !== '') {
            var pos = this.elmDTA.accessCard.reimbsubscriptiontypeid.indexOf("_PRIVATE")
            var tariff = this.elmDTA.accessCard.reimbsubscriptiontypeid.substring(0,pos)
            rslt = await new SubscriptionApi().getSubscription(tariff) as any
            this.customFeeDescription = rslt.data.Description
            this.tariffsCtn = rslt.data.Tariffs[0].kwhfee
          }
        }
      }
      console.log(rslt)
    } catch (error) {
      console.log(error)
    }
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  next (step: number) {
    // RFID
    if (step === 1) {
      this.$store.commit('chargerInstallationState/setStep', 'StepResume')
    } else {
      // PLUG & PLAY
      this.$store.commit('chargerInstallationState/setStep', 'StepReimburseMainUser')
    }
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1) {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
        this.cp = rslt.data
        this.state = 1
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }



  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep () {
    this.$emit('next')
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
