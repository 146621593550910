




























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import RelationSelector from '../../relationTree/RelationSelector.vue'
import RelationContactSelector from '../../relationcontact/RelationContactSelector.vue'
import { mapGetters } from 'vuex'
import RelationApi from '@/services/RelationApi'
import RelationContactApi from '@/services/RelationContactApi'

@Component({
  components: {
    RelationSelector,
    RelationContactSelector
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  }
})
export default class StepTwo extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Prop(Boolean) readonly isReset: any

  loading = false
  state = 0
  error: string | null = null
  icon = ''
  color = 'primary'
  chargerType = null
  tmpRlID = 0

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  previousStep () {
    this.$emit('back')
  }

  async nextStep () {
    try {
      // console.log('form')
      // console.log(this.formSynced)
      // console.log('-----------')
      /* if (this.chargerType === '1' && this.formSynced.relationId === this.formSynced.relationContactId) {
        const data = await new RelationApi().convertRelation(this.formSynced.relationContactId) as any
        this.formSynced.relationId = data.data.relationid
      } */
      // console.log(this.formSynced)
      if (this.chargerType === '1') {
        try {
          const dt = await new RelationContactApi(0).findContactsByRelationContactId(this.formSynced.relationContactId) as any
          const dtAdrs = dt.data
          // console.log(dtAdrs)
          this.formSynced.Location.Street = dtAdrs.Street
          this.formSynced.Location.HouseNumber = dtAdrs.HouseNumber
          this.formSynced.Location.City = dtAdrs.City
          this.formSynced.Location.ZipCode = dtAdrs.PostalCode
          this.formSynced.Location.Country = dtAdrs.Country
        } catch (error) {
          console.log(error)
        }
      } else {
        if (this.chargerType === '2') {
          const dt = await new RelationApi(0).getRelationById(this.formSynced.relationId) as any
          // console.log(dt)
        }
      }
      this.$emit('next')
    } catch (error) {
      console.log(error)
    }
  }

  reset () {
    this.chargerType = null
  }

  cancel () {
    this.$emit('cancel')
  }

  get userData (): any {
    // @ts-ignore
    if (this.userDTA.data) {
      // @ts-ignore
      return this.userDTA.data.Relation.Id
    } else {
      return 0
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
