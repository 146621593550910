



















































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import TokenApi from '../../../services/TokenApi'
import DriverInput from './DriverInput.vue'

@Component({
  components: {
    DriverInput
  },
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop({ type: Boolean, default: false }) readonly isReset: any
   @Prop({ type: Boolean, default: false }) readonly isUpdate: any
   @Prop({ type: Boolean, default: false }) readonly isSavingToken: any
  @PropSync('tokenForm', { type: Array }) tokenFormSync!: any
  @Prop(Array) readonly contactTab: any

  isChecking = false
  isChecked = false

  /* tokenForm = [
    {
        printednumber : '',
        description : ''
    }
  ] */

  get isNext () {
    // check if there no doubles
    // check if there are no invalides
    // check if there minimum one valide
    let isValid =  this.tokenFormSync.length > 0
    for (let index = 0; index < this.tokenFormSync.length && isValid; index++) {
      if (!this._.isEmpty(this.tokenFormSync[index].user)) {
        isValid = this.tokenFormSync[index].user.id > 0
      } else {
        isValid = false
      }
    }

    /* if (isValid) {
      // check if double
    } */

    return isValid
  }

  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  errMSG = ''

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  addTokenInput () {
    this.tokenFormSync.push(
      {
        printednumber : '',
        description : ''
      }
    )
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  back () {
    this.$emit('back')
  }

  get userDTA (): any {
    // @ts-ignore
    return this.userDT
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.isCheck = false
    this.loading = false
  }

  removeToken (index: number){
    this.tokenFormSync.splice(index, 1)
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1 || this.formSynced.ChargepointSerialNumber === 'PIETER') {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        if (this.formSynced.ChargepointSerialNumber === 'PIETER') {
          this.isCheck = true
          this.state = 1
        } else {
          const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
          this.cp = rslt.data
          this.state = 1
        }
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep () {
    // this.$store.commit('chargerInstallationState/setSerialNumber', this.formSynced.ChargepointSerialNumber)
    this.$emit('next')
  }

  updateStep () {
    // this.$store.commit('chargerInstallationState/setSerialNumber', this.formSynced.ChargepointSerialNumber)
    this.$emit('updateStep')
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
