































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { isRequired } from '../../../functions/formRules'

@Component({
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('tokenForm', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any
  @Prop(Array) readonly contactTab: any

  isChecking = false
  checkState = -1 // -1 nothing, 0 success, 1 warning, 2 error
  rulesToken: Array<any> = [
    isRequired('This field is required')
  ]

  errors:any = []

  errMSG = ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  icon = ''
  color = 'primary'


  reset () {
    this.state = 0
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }
  
  clearInput() {
    this.formSynced.user = {
      relationcontactid: 0,
      relationid: 0
    }
  }

  get userDTA (): any {
    // @ts-ignore
    return this.userDT
  }

  showDriver (item: any) {
    // console.log(item)
    // return {'relationid': item.relationid, 'relationcontactid': item.id, 'description': item.description}
    return item
  }

  nextStep () {
    this.$emit('next')
  }
}
