import { ChargeLocation } from '@/services/ChargeLocationApi'

export interface Session {
  Id: string
  TokenUID: string
}

export interface DateTimeOutType {
  dateTimeOut: string
}
export interface ConnectorInterface {
  id: string
  state?: string
  sessionid?: string
  sessionstate?: string
  Session?: Session
  State?: string
  SessionState?: string
}

export interface Placement {
  groupingparam1: string
  locationlabel: string
  locationlat: number
  locationlng: number
  address: string
  street: string
  housenr: string
  postalcode: string
  city: string
  Country: string
  evtype: string
  published: string
  reimbursement: number
  hasreimbursement: boolean
}

export interface ChargePointUppercaseType {
  Id: string
  State: string
  Description: string
  PublicIdentifier: string
  Location: ChargeLocation
  Connectors: ConnectorInterface[]
}
export interface ChargePoint {
  id: string
  key: number
  type?: string
  haschildren?: boolean
  ownerdesc?: string
  chargeboxidentifier: string
  model?: string
  chargepointserialnumber: string
  onlinestatedatetime?: string
  description: string
  state: string
  lastheartbeat?: string
  landownerdescription?: string
  landownerreference?: string
  connectors: ConnectorInterface[]
  placements: Placement[]
}

export interface Settings {
  evtype: string
  isplugandcharge: number
  isreimbtowhitelist: number
  published: number
}

export enum States {
  available = 'available',
  AVAILABLE = 'AVAILABLE',
  offline = 'Offline',
  OFFLINE = 'OFFLINE',
  occupied = 'OCCUPIED',
  parking = 'PARKING',
  charging = 'CHARGING',
  empty = ''
}
