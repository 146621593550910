















import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', elm: 'chargerInstallationState/elmState' })
  }
})
export default class StepOne extends Vue {
  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  next (step: number) {
    // RFID
    if (step === 1) {
      this.$store.commit('chargerInstallationState/setIsPlugCharge', false)
      this.$store.commit('chargerInstallationState/setIsRFID', true)
      this.$store.commit('chargerInstallationState/setStep', 'StepAccessCharger')
    } else {
      // Plug & Pay
      // Check if is company : 
      // console.log(this.elmDTA)
      this.$store.commit('chargerInstallationState/setIsPlugCharge', true)
      this.$store.commit('chargerInstallationState/setIsRFID', false)
      if (this.elmDTA.isCompany) {
        this.$store.commit('chargerInstallationState/setStep', 'StepResume')
      } else {
        this.$store.commit('chargerInstallationState/setStep', 'StepReimburseMainUser')
      }
    }
  }
}
