export function addressHelper (address: any) {
  const finAddress: any = {}
  for (let i = 0; i < address.length; i++) {
    const element = address[i]
    for (let j = 0; j < element.types.length; j++) {
      const e = element.types[j]
      if (e === 'street_number') {
        finAddress.housenumber = element.long_name
      } else if (e === 'route') {
        finAddress.street = element.long_name
      } else if (e === 'locality') {
        finAddress.city = element.long_name
      } else if (e === 'country') {
        finAddress.country = element.short_name
      } else if (e === 'postal_code') {
        finAddress.postalcode = element.long_name
      }
    }
  }
  return finAddress
}
