


























































































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AutocompletePresetTokenList from '../../presets/AutocompletePresetSubscriptionList.vue'
import ConnectCardWithCharger from './ConnectCardWithCharger.vue'

@Component({
  components: {
    AutocompletePresetTokenList,
    ConnectCardWithCharger
  },
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState', currentStep: 'chargerInstallationState/stepName', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any
  @Prop(Array) readonly tokensTab: any

  fee = '1'
  checkbox = false
  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  customFee = ''
  token = ''

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  addToken () {
    if (!this._.isEmpty(this.token)) {
      this.$store.commit('chargerInstallationState/addReimbToken', this.token)
    }
    this.token = ''
  }

  deleteItem (index: number) {
    this.$store.commit('chargerInstallationState/deleteReimbToken', index)
  }

  deleteRfidItem (index: number) {
    this.$store.commit('chargerInstallationState/deleteRFIDReimbToken', index)
  }

  next () {
    // console.log('CUSTOM FEE BEFORE NEXT')
    // console.log(this.customFee)
    // RFID
    if (this.fee === '1') {
      // set custom fee
      const dta = {
        isCreg : true,
        code: 'SUB-PARTNER-UPPER-NOUPLIFT'
      }
      this.$store.commit('chargerInstallationState/setPlugChargeCreg', dta)
      const dtaBis = {
        isCustomfee : false,
        val: 'SUB_PARTNER_CREG'
      }
      this.$store.commit('chargerInstallationState/setPlugChargeCustomFee', dtaBis)
    } else {
      if (this.fee === '2') {
        // set custom fee
        const dta = {
          isCreg : false,
          code: 'SUB-PARTNER-UPPER-NOUPLIFT'
        }
        this.$store.commit('chargerInstallationState/setPlugChargeCreg', dta)
        const dtaBis = {
          isCustomfee : true,
          val: this.customFee
        }
        this.$store.commit('chargerInstallationState/setPlugChargeCustomFee', dtaBis)
      }
    }
    if (this.elmDTA.isCompany) {
      this.$store.commit('chargerInstallationState/setStep', 'StepResume')
    } else {
      if (this.elmDTA.isPlugCharge) {
        this.$store.commit('chargerInstallationState/setStep', 'StepResume')
      } else {
        if (this.fee === '1') {
          this.$store.commit('chargerInstallationState/setReimbWhiteList', 'SUB_PARTNER_CREG_PRIVATE')
        } else {
          this.$store.commit('chargerInstallationState/setReimbWhiteList', this.customFee)
        }
        this.$store.commit('chargerInstallationState/setStep', 'StepResume')
      }
    }
    // console.log('NEXT')
    // console.log(this.elmDTA)
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1) {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
        this.cp = rslt.data
        this.state = 1
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep () {
    this.$emit('next')
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
