





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import StepThreeNewLocation from './StepThreeNewLocation.vue'
import { mapGetters } from 'vuex'
import StepChargerType from './StepChargerType.vue'
import StepReimburseMainUser from './StepReimburseMainUser.vue'
import StepSelectReimburser from './StepSelectReimburser.vue'
import StepResume from './StepResume.vue'
import StepUsageReimburse from './StepUsageReimburse.vue'
import StepAccessCharger from './StepAccessCharger.vue'
import StepCardAccess from './StepCardAccess.vue'
import StepPublicPay from './StepPublicPay.vue'
import StepRequiredFee from './StepRequiredFee.vue'
import StepVisible from './StepVisible.vue'
import StepOtherUsage from './StepOtherUsage.vue'
import StepCardCharged from './StepCardCharged.vue'
import SubscriptionApi from '../../../services/SubscriptionApi'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    StepThreeNewLocation,
    StepChargerType,
    StepReimburseMainUser,
    StepSelectReimburser,
    StepResume,
    StepUsageReimburse,
    StepAccessCharger,
    StepCardAccess,
    StepPublicPay,
    StepRequiredFee,
    StepVisible,
    StepOtherUsage,
    StepCardCharged
  },
  computed: {
    ...mapGetters({
      currentStep: 'chargerInstallationState/stepName',
      elm: 'chargerInstallationState/elmState',
      userDTA: 'user/userInfo'
    })
  }
})
export default class StepThree extends Vue {
  @Prop(Boolean) readonly isSaving: any
  isStart = false
  tokensTab = []
  loadingTokens = false
  isPreset = false
  startWizard() {
    this.isStart = true
  }

  startPresetWizard() {
    this.isPreset = true
    this.isStart = true
  }

  mounted() {
    this.getDetails()
  }

  async getDetails() {
    try {
      this.loadingTokens = true
      this.tokensTab = []
      const dt: any = await new SubscriptionApi().list()
      this.tokensTab = dt.data
      this.loadingTokens = false
    } catch (error) {
      console.log(error)
    }
  }

  closeRQ() {
    this.$emit('closeRQ')
  }

  previous() {
    this.$store.commit('chargerInstallationState/setReset')
    this.$emit('back')
  }

  previousStep() {
    // console.log('CALL PREVIOUS FUNCTION')
    // console.log(this.curStep)
    // console.log(this.curStep)
    if (this.curStep === 'StepChargerType' || this.isPreset) {
      // if (this.isPreset) {
      this.isStart = false
      this.isPreset = false
      this.$store.commit('chargerInstallationState/setReset')
      if (this.curStep === 'StepChargerType') {
        this.$store.commit('chargerInstallationState/setIsPlugCharge', false)
        this.$store.commit('chargerInstallationState/setIsRFID', false)
      }
      this.$store.commit('chargerInstallationState/setPreviousStepName')
    } else {
      this.$store.commit('chargerInstallationState/setPreviousStepName')
      if (this.curStep === 'StepChargerType') {
        this.$store.commit('chargerInstallationState/setIsPlugCharge', false)
        this.$store.commit('chargerInstallationState/setIsRFID', false)
      }
      if (this.curStep === 'StepAccessCharger') {
        this.$store.commit('chargerInstallationState/setIsGuestCharge', false)
      }
      if (this.curStep === 'StepVisible') {
        this.$store.commit('chargerInstallationState/setIsPublishMap', false)
      }

      if (this.curStep === 'StepReimburseMainUser') {
        this.$store.commit(
          'chargerInstallationState/setPlugChargeIsReimbursement',
          false
        )
      }

      if (this.curStep === 'StepSelectReimburser') {
        this.$store.commit(
          'chargerInstallationState/setPlugChargeReimburser',
          ''
        )
        this.$store.commit('chargerInstallationState/setWLReimburser', '')
      }

      if (this.curStep === 'StepUsageReimburse') {
        const dta = {
          isCreg: false,
          code: ''
        }
        this.$store.commit('chargerInstallationState/setPlugChargeCreg', dta)
        const dtaBis = {
          isCustomfee: false,
          val: ''
        }
        this.$store.commit(
          'chargerInstallationState/setPlugChargeCustomFee',
          dtaBis
        )
        this.$store.commit('chargerInstallationState/setWhiteList', '')
      }

      if (this.curStep === 'StepPublicPay') {
        // console.log('START CLEARING')
        this.$store.commit(
          'chargerInstallationState/setGuestchargeIsFree',
          false
        )
        this.$store.commit(
          'chargerInstallationState/setGuestchargeIsBlueCornerFee',
          false
        )
        this.$store.commit('chargerInstallationState/setIsCustomFee', false)
        this.$store.commit(
          'chargerInstallationState/setGuestChargeIsReimbursement',
          false
        )
        const dtaBis = {
          isCustomfee: false,
          val: null
        }
        this.$store.commit(
          'chargerInstallationState/setGuestChargeCustomFee',
          dtaBis
        )
        // clear tokens:
        // console.log('CLEAR TOKENS')
        this.$store.commit('chargerInstallationState/setResetToken')
      }
    }
  }

  get curStep(): any {
    // @ts-ignore
    return this.currentStep
  }

  back() {
    this.$emit('back')
  }

  cancel() {
    this.$emit('cancel')
  }

  save() {
    this.$emit('save')
  }

  finish() {
    this.$emit('finish')
  }

  get usrDTA(): any {
    // @ts-ignore
    return this.userDTA
  }
}
