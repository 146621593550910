



























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import RelationSelector from '../../relationTree/RelationSelector.vue'

@Component({
  components: {
    RelationSelector
  }
})
export default class StepFour extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  loading = false
  state = 0
  error: string | null = null
  icon = ''
  color = 'primary'

  get reimbursementTarrif () {
    return [
      { key: 'CREG', text: `Creg ${this.t('$vuetify.chargepoint.step4.tarriff').toLowerCase()}` }
    ]
  }

  get chargerTypeOptions () {
    return [
      { key: 'PRIVATE', text: this.t('$vuetify.chargepoint.step4.private'), hint: this.t('$vuetify.chargepoint.step4.privateHint') },
      { key: 'PUBLICPUBLISHED', text: this.t('$vuetify.chargepoint.step4.publicPub'), hint: this.t('$vuetify.chargepoint.step4.publicPubHint') },
      { key: 'PUBLICNOTPUBLISHED', text: this.t('$vuetify.chargepoint.step4.publicNotPub'), hint: this.t('$vuetify.chargepoint.step4.publicNotPubHint') }
    ]
  }

  get reimbursementTypes () {
    const tmp = []
    tmp.push({ key: 'NO', text: this.t('$vuetify.chargepoint.step4.noReimbursement'), hint: this.t('$vuetify.chargepoint.step4.noReimbursementHint') })
    tmp.push({ key: 'BLUECORNER', text: this.t('$vuetify.chargepoint.step4.rimbursBC'), hint: this.t('$vuetify.chargepoint.step4.rimbursBCHint') })
    let extra = ''
    if (this.formSynced.chargerType === 'PRIVATE') {
      extra = this.t('$vuetify.chargepoint.step4.rimbursPartnerExtra')
    }
    tmp.push({ key: 'MSP', text: this.t('$vuetify.chargepoint.step4.rimbursPartner'), hint: `${extra}${this.t('$vuetify.chargepoint.step4.rimbursPartnerHint')}`, disabled: extra })
    tmp.push({ key: 'REIMB-TO-ALL', text: this.t('$vuetify.chargepoint.step4.rimbursAllSes'), hint: this.t('$vuetify.chargepoint.step4.rimbursAllSesHint') })
    tmp.push({ key: 'REIMB-TO-TOKEN', text: this.t('$vuetify.chargepoint.step4.rimbursSpecificSes'), hint: this.t('$vuetify.chargepoint.step4.rimbursSpecificSesHint'), disabled: true })
    return tmp
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  next () {
    this.$emit('next')
  }

  @Watch('selected')
  selectedOnChanged (val: any, oldVal: any) {
    this.error = ''
  }
}
