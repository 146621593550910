import { AxiosRequestConfig } from 'axios'
import BaseApi from './BaseApi'

export interface IGetLocation {
  directonly?: 1
  debug?: 1
  relid?: number
}

export default class LocationApi extends BaseApi {
  constructor (type = 0) {
    super('Location', type)
  }

  async TenLastAuthorizesFromPlacents (ids: Array<number|string>) {
    const options = { url: `/${process.env.VUE_APP_API_VERSION}/authorize/BCO/lasttenbycp?ids=${JSON.stringify(ids)}` }
    const dt = await this.getApi(options) as any
    return dt.data
  }

  async getLocations (properties: IGetLocation = {}): Promise<any> {
    const opts: AxiosRequestConfig = {
      url: `/${process.env.VUE_APP_API_VERSION}/chargelocation/BCO/list`,
      method: 'get',
      params: properties
    }
    const dt = await this.getApi(opts) as any
    return dt.data
  }
}
