var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.t('$vuetify.myOrganisation.drivers'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.t('$vuetify.myOrganisation.search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:(_vm.formSynced.relationId === item.id ? 'pointer;background-color: rgba(0, 0, 0, .05);' : ''),on:{"click":function($event){_vm.formSynced.relationId = item.id;_vm.formSynced.relationContactId = item.id}}},[_c('td',[_vm._v(_vm._s(item.firstname))]),_c('td',[_vm._v(_vm._s(item.lastname))]),_c('td',[_vm._v(_vm._s(item.email))]),(item.address)?_c('td',[_vm._v(" "+_vm._s(item.address.street)+" "),(item.address.street)?_c('span',[_vm._v(",")]):_vm._e(),_vm._v(" "+_vm._s(item.address.housenumber)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.city)+" "),(item.address.city)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(item.address.postalcode)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.country)+" ")]):_vm._e(),(false)?_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[(item.hasportal === 0)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("$vuetify.icons.userNotAllowedAccount")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("$vuetify.icons.userAllowedAccount")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("hasPortal")(item.hasportal))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","x-small":""},on:{"click":function($event){return _vm.editDriver(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("uil uil-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit driver ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","small":""},on:{"click":function($event){_vm.current = item; _vm.dialogSwitch = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-switch-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Change relation ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({name:'navchargepointsdetails'})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-ev-station")])],1)]}}],null,true)},[_c('span',[_vm._v(" Chargepoint ")])])],1):_vm._e()])]}}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogSwitch),callback:function ($$v) {_vm.dialogSwitch=$$v},expression:"dialogSwitch"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Change organisation")])]),_c('v-card-text',[_c('div',{staticClass:"row"},[_c('v-flex',{staticClass:"ml-3",attrs:{"xs5":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('RelationSelector',{model:{value:(_vm.payload.relationid),callback:function ($$v) {_vm.$set(_vm.payload, "relationid", $$v)},expression:"payload.relationid"}})],1)],1)],1),(_vm.payload.relationid)?_c('v-flex',{staticClass:"ml-3",attrs:{"xs5":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.relationInfo.Description)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.relationInfo.VATnumber)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.relationInfo.Email)+" "),_c('br')])],1)],1):_vm._e()],1),_c('small',[_vm._v("*indicates required field")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogSwitch = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.switchDriver()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('NewModal',{attrs:{"parent":{},"driver":_vm.current,"value":_vm.showModal,"isEdit":_vm.isEdit},on:{"update:value":function($event){_vm.showModal=$event},"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }