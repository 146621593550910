



























































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import Connector from '../../components/chargepoint/Connector.vue'
import { find } from 'lodash'
import TechnicalData from '../../components/chargepoint/TechnicalData.vue'
import RemoteStart from './RemoteStart'
import Reset from './Reset'
import ChargePointApi from '../../services/ChargePointApi'
import moment from 'moment'
import TariffDialog from './TariffDialog.vue'
import RelationApi from '@/services/RelationApi'
import UserApi from '@/services/UserApi'

@Component({
  components: {
    Connector,
    TechnicalData,
    RemoteStart,
    TariffDialog,
    Reset
  },
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  }
})
export default class ChargepointCard extends Vue {
  @Prop(String) readonly chargepointId: any
  @Prop(Object) readonly usrDTA: any
  @Prop(Boolean) readonly isWizard: any
  @PropSync('form', { type: Object }) formSynced!: any

  absolute = true

  tariffsTab: Array<any> = []

  /* chargeCard = {
    isChargeCardAccess: false,
    groups: [],
    deleteGroups: []
  } */

  loaders = {
    groups: false,
    overlay: false,
    tariff: false
  }

  payload = {
    Id: '',
    Code: '',
    Description: '',
    TokenWhiteListList: [],
    isNew: false,
    isEdit: false,
    index: 0
  }

  payloadToken = {
    token: '',
    isNew: false,
    oldTarriff: '',
    newTarriff: '',
    isReimbursement: false
  }

  state = {
    isNew: false,
    isDuplicate: false,
    isEdit: false
  }

  step = 0

  warningData = {
    type: '',
    item: 0,
    index: 0
  }

  currentIndex = 0

  mounted () {
    this.whiteListGroup()
  }

  async whiteListGroup () {
    try {
      // console.log('GROUP WHITELISTGROUP MOUNTED')
      this.loaders.groups = true
      // console.log(this.formSynced.relationId)
      const data = await new RelationApi().getWhiteListGroup(this.formSynced.relationId) as any
      this.formSynced.chargeCard.groups = data.data.records
      this.formData()
      this.loaders.groups = false
      // console.log('GROUP WHITELISTGROUP MOUNTED')
    } catch (error) {
      console.log(error)
    }
  }

  formData () {
    // console.log('START FORMATTING')
    for (let index = 0; index < this.formSynced.chargeCard.groups.length; index++) {
      const group: any = this.formSynced.chargeCard.groups[index]
      // console.log('group')
      // console.log(group.TokenWhiteListList.length)
      for (let index = 0; index < group.TokenWhiteListList.length; index++) {
        const tokens = group.TokenWhiteListList[index].Tokens
        const list = group.TokenWhiteListList[index]
        // console.log('List')
        // console.log(tokens)
        for (let index = 0; index < tokens.length; index++) {
          const token = tokens[index]
          const format = {
            groupCode: group.Code,
            listCode: list.Code,
            token: token,
            isNew: false,
            oldTarriff: { Code: list.TariffCode },
            newTarriff: { Code: list.TariffCode },
            isReimbursement: list.TariffCode !== 'COSTLESS'
          }
          this.$set(tokens, index, format)
        }
      }
    }
  }

  async getListTariff () {
    try {
      this.loaders.tariff = true
      const data = await new RelationApi().getTariffs(this.formSynced.relationId) as any
      this.tariffsTab = data.data.records
      this.loaders.tariff = false
    } catch (error) {
      console.log(error)
    }
  }

  addToken () {
    console.log('ADD TOKEN : ')
    this.payloadToken.isNew = true
    this.payloadToken.oldTarriff = 'COSTLESS'
    this.payloadToken.newTarriff = 'COSTLESS'
    let isFound = false
    // @ts-ignore
    // search if costless exist
    for (let index = 0; index < this.formSynced.chargeCard.groups[this.currentIndex].TokenWhiteListList.length && !isFound; index++) {
      // @ts-ignore
      const element = this.formSynced.chargeCard.groups[this.currentIndex].TokenWhiteListList[index]
      if (element.TariffCode === 'COSTLESS') {
        // @ts-ignore
        this.formSynced.chargeCard.groups[this.currentIndex].TokenWhiteListList[index].Tokens.push(this.payloadToken)
        isFound = true
      }
    }
    if (!isFound) {
      const tmpList: any = {
        TariffCode: 'COSTLESS',
        isNew: true,
        Tokens: []
      }
      tmpList.Tokens.push(this.payloadToken)
      console.log(this.currentIndex)
      console.log(this.formSynced.chargeCard.groups[this.currentIndex])
      // @ts-ignore
      this.formSynced.chargeCard.groups[this.currentIndex].TokenWhiteListList.push(tmpList)
    }
    this.payloadToken = {
      token: '',
      isNew: false,
      oldTarriff: '',
      newTarriff: '',
      isReimbursement: false
    }
  }

  backToGroup () {
    this.step = 0
    this.currentIndex = 0
  }

  showGroup (index: any) {
    this.formSynced.chargeCard.currentIndex = index
    this.currentIndex = index
    this.getListTariff()
    this.step = 2
  }

  duplicateGroup (item: any) {
    console.log(item)
  }

  warningModal (type: any, item: any, index: any) {
    this.warningData = {
      type: type,
      item: item,
      index: index
    }
    this.loaders.overlay = true
  }

  cancelWarning () {
    this.warningData = {
      type: '',
      item: 0,
      index: 0
    }
    this.loaders.overlay = false
  }

  removeGroup (item: any, index: any) {
    if (!item.isNew) {
      // @ts-ignore
      this.formSynced.chargeCard.deleteGroups.push(item)
    }
    // @ts-ignore
    this.formSynced.chargeCard.groups[index].isDelete = true
    this.formSynced.chargeCard.groups.splice(index, 1)
    this.cancelWarning()
  }

  cleanup () {
    this.state.isDuplicate = false
    this.state.isEdit = false
    this.state.isNew = false
    this.payload = {
      Id: '',
      Code: '',
      Description: '',
      TokenWhiteListList: [],
      isNew: false,
      isEdit: false,
      index: 0
    }
  }

  newGroup () {
    this.cleanup()
    this.state.isNew = true
    this.step = 1
  }

  editGroup (item: any, index: any) {
    this.cleanup()
    this.payload = {
      Id: item.Id,
      Code: item.Code,
      Description: item.Description,
      TokenWhiteListList: item.TokenWhiteListList,
      isNew: item.isNew,
      index: index,
      isEdit: true
    }
    this.state.isEdit = true
    this.step = 1
  }

  createGroup () {
    this.payload.isNew = true
    // @ts-ignore
    this.formSynced.chargeCard.groups.push(this.payload)
    this.step = 0
  }

  updateGroup (index: any) {
    this.$set(this.formSynced.chargeCard.groups, this.payload.index, this.payload)
    this.step = 0
  }

  async save () {
    console.log('START SAVING')
    // CREATE + EDIT
    for (let index = 0; index < this.formSynced.chargeCard.groups.length; index++) {
      const element: any = this.formSynced.chargeCard.groups[index]
      if (element.isNew) {
        // CREATE GROUP
        const payload = {
          Id: '',
          Code: element.Code,
          Description: element.Description,
          WhiteListList: [] // pas op naam veld verschillend van wat er doorgestuurd word
          //  WhiteListList: element.TokenWhiteListList // pas op naam veld verschillend van wat er doorgestuurd word
        }
        const data = await new RelationApi().createGroup(this.formSynced.relationId, payload) as any
        // @ts-ignore
        this.formSynced.chargeCard.groups[index].Code = data.data.Code
        // this.$set(tokens, index, format)
      } else {
        if (element.isEdit) {
          // CREATE GROUP
          const payload = {
            Id: element.Id,
            Code: element.Code,
            Description: element.Description,
            WhiteListList: element.TokenWhiteListList // pas op naam veld verschillend van wat er doorgestuurd word
          }
          const data = await new RelationApi().createGroup(this.formSynced.relationId, payload) as any
        }
      }
    }
    // DELETE
    for (let index = 0; index < this.formSynced.chargeCard.deleteGroups.length; index++) {
      const element: any = this.formSynced.chargeCard.deleteGroups[index]
      const data = await new RelationApi().removeWLGroup(this.formSynced.relationId, element.Code) as any
    }
    // TOKENS ADD
    for (let index = 0; index < this.formSynced.chargeCard.groups.length; index++) {
      const group: any = this.formSynced.chargeCard.groups[index]
      console.log('group')
      console.log(group)
      for (let index = 0; index < group.TokenWhiteListList.length; index++) {
        const tokens = group.TokenWhiteListList[index].Tokens
        const list = group.TokenWhiteListList[index]
        console.log('List')
        console.log(tokens)
        for (let index = 0; index < tokens.length; index++) {
          const token = tokens[index]
          console.log(token)
          if (token.isNew) {
            console.log('NEW TOKEN')
            const payload = {
              PrintedNumber: token.token
            }
            // search Code to move
            let to = ''
            for (let index = 0; index < group.TokenWhiteListList.length && (to === ''); index++) {
              const elmListSearch = group.TokenWhiteListList[index]
              if (!token.isReimbursement) {
                if (elmListSearch.TariffCode === 'COSTLESS' && !elmListSearch.isNew) {
                  console.log('pre found')
                  console.log(elmListSearch)
                  to = elmListSearch.Code
                }
              } else {
                if (elmListSearch.TariffCode === token.newTarriff.Code) {
                  to = elmListSearch.Code
                }
              }
            }
            // code not found create new list
            if (to === '') {
              console.log('NOT FOUND')
              let payloadBody = {
                TariffCode: token.newTarriff.Code
              }
              if (!token.isReimbursement) {
                payloadBody = {
                  TariffCode: 'COSTLESS'
                }
              }
              const bankRslt: any = await new RelationApi().createWhiteList(group.Code, payloadBody)
              to = bankRslt.data.Code
            }
            console.log(group.Code)
            console.log(to)
            const addPrntNumbRslt: any = await new RelationApi().addprintedNumber(group.Code, to, payload)
          } else {
            if ((token.newTarriff.Code !== token.oldTarriff.Code) || (!token.isReimbursement && token.oldTarriff.Code !== 'COSTLESS')) {
              console.log('MOVE')
              console.log(token)
              const payload = {
                Tokens: [token.token]
              }
              // search Code to move
              let to = ''
              for (let index = 0; index < group.TokenWhiteListList.length && (to === ''); index++) {
                const elmListSearch = group.TokenWhiteListList[index]
                if ((!token.isReimbursement && token.oldTarriff.Code !== 'COSTLESS')) {
                  if (elmListSearch.TariffCode === 'COSTLESS' && !elmListSearch.isNew) {
                    to = elmListSearch.Code
                  }
                } else {
                  if (elmListSearch.TariffCode === token.newTarriff.Code) {
                    to = elmListSearch.Code
                  }
                }
              }
              // code not found create new list
              if (to === '') {
                const payloadBody = {
                  TariffCode: token.newTarriff.Code
                }
                const bankRslt: any = await new RelationApi().createWhiteList(token.groupCode, payloadBody)
                to = bankRslt.data.Code
              }
              // if found move group otherwise use to from bankRslt.data.Code
              const rsltdta: any = await new RelationApi().moveTokenFromGroup(token.groupCode, token.listCode, to, payload)
            }
          }
        }
      }
    }
    console.log('SAVED')
  }
}
