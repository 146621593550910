





























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, PropSync, Ref, Prop } from 'vue-property-decorator'
import TokenApi from '../../../services/TokenApi'
import { isRequired } from '../../../functions/formRules'

@Component
export default class StepOne extends Vue {
  @PropSync('tokenAllForm', { type: Array }) formAllSynced!: any
  @PropSync('tokenForm', { type: Object }) formSynced!: any
  @PropSync('tokensTab', { type: Array }) tabSynced!: any
  @Prop(Boolean) readonly isReset: any
  @Ref() readonly form!: any

  showError = false
  isChecking = false
  checkState = -1 // -1 nothing, 0 success, 1 warning, 2 error
  valid= true
  doneTypingInterval = 1000
  typingTimer = setTimeout(() => {
      // this.preCheckPN()
    }, this.doneTypingInterval)

  rulesToken: Array<any> = [
    isRequired('This field is required')
  ]

  errors:any = []
  toggleInfo = false
  clearToken () {
    this.formSynced.printednumber = ''
    this.form.resetValidation()
    this.form.reset()
    this.errors = []
    this.checkState = -1
    this.formSynced.isValidToken = false
  }

  removeToken () {
    console.log('removeToken')
    this.$emit('removeToken')
    this.clearToken()
  }

  get tokenColor () {
    let color = ''
    switch (this.checkState) {
      case 0: color = 'success'; break;
      case 1: color = 'warning'; break;
      case 2: color = 'error'; break;
      default:
        break;
    }
    return color
  }

  clearTime () {
    clearTimeout(this.typingTimer)
    if (this.formSynced.printednumber) {
      this.typingTimer = setTimeout(() => {
        this.preCheckPN()
      }, this.doneTypingInterval)
    }
  }

  preCheckPN () {
    this.checkPrintedNumber()
  }

  /* @Watch('formSynced.printednumber')
  printedNumberOnChanged (val: any) {
    if (val && val.length > 13 && !this._.isEmpty(val)) {
      this.formSynced.printednumber = val.replace(/\s+/g, '');
      this.formSynced.printednumber = this.formSynced.printednumber.toUpperCase()
      this.checkPrintedNumber()
    } else {
      this.errors = []
      this.checkState = -1
    }
  } */

  containsToken (token: string) {
    let count = 0
    for (let index = 0; index < this.tabSynced.length; index++) {
      const element = this.tabSynced[index];
      if (element === token) {
        count++
      }
    }
    console.log(count >= 1)
    return (count >= 1)
  }

  isDouble (token: string) {
    if(this.tabSynced.indexOf(token) > - 1) {
      return true
    }
    return false
  }

  async checkPrintedNumber () {
    this.isChecking = true
    // check if already is in table
    // if (!this.isDouble(this.formSynced.printednumber)) {
    const msg = ''
    this.errors = []
    try {
      // console.log('[DEBUG] Start check')
      const dt: any = await new TokenApi().check(this.formSynced.printednumber)
      this.checkState = 0
      this.formSynced.isValidToken = true
      // this.tabSynced.push(this.formSynced.printednumber)
    } catch (error: any) {
      // console.log(error)
      this.checkState = error.status === 'WARNING' ? 1 : 2
      if (this.checkState === 1) {
        // this.tabSynced.push(this.formSynced.printednumber)
        this.formSynced.isValidToken = true
      } else {
        this.formSynced.isValidToken = false
      }
      const msg = '$vuetify.errorTXT.' + error.errorcode
      this.errors = this.t(msg)
      this.formSynced.errors = this.errors
    }
    // }
    /* else {
      this.checkState = 2
    } */
    this.isChecking = false
    /* try {
      // check if not already filled the same card number
      // if (!tokensTab[])
      if (!this.isDouble(this.formSynced.printednumber)) {
        const dt: any = await new TokenApi().check(this.formSynced.printednumber)
        this.checkState = 0
        this.tabSynced.push(this.formSynced.printednumber)
      } else {
        this.checkState = 2
        const msg = '$vuetify.errorTXT.' + 'ALUSEDCARD'
        this.errors = this.t(msg)
      }
    } catch (e: any) {
      this.checkState = e.status === 'WARNING' ? 1 : 2
      if (this.checkState === 1) {
        if (!this.isDouble(this.formSynced.printednumber)) {
          console.log('IN IF')
          this.checkState = 1
          this.tabSynced.push(this.formSynced.printednumber)
          const msg = '$vuetify.errorTXT.' + e.errorcode
          this.errors = this.t(msg)
        } else {
          this.checkState = 2
          const msg = '$vuetify.errorTXT.' + 'ALUSEDCARD'
          this.errors = this.t(msg)
        }
      } else {
        const msg = '$vuetify.errorTXT.' + e.errorcode
        this.errors = this.t(msg)
      }
    } */
    // this.isChecking = false
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.clearToken()
    }
  }
}
