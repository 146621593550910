


















import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class StepOne extends Vue {
  next (step: number) {
    if (step === 1) {
      this.$store.commit('chargerInstallationState/setStep', 'StepResume')
    } else {
      this.$store.commit('chargerInstallationState/setStep', 'StepCardCharged')
    }
  }
}
