


































































































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import TokenApi from '../../../services/TokenApi'
import { isRequired } from '../../../functions/formRules'
import TariffTabCardDetails from '../TariffTabCardDetails.vue'

@Component({
  components: {
    TariffTabCardDetails,
  },
  computed: {
    ...mapGetters({ elm: 'chargerInstallationState/elmState', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('tokenForm', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any
  @Prop(Boolean) readonly disabled: any
  @Prop(Array) readonly subscriptionsTab: any
  @PropSync('isUpdate', { type: Boolean, default: false }) isUpdateSync: any

  isPublicCharging = false
  isChecking = false
  checkState = -1 // -1 nothing, 0 success, 1 warning, 2 error
  // formSynced.currentSub = -1
  rulesToken: Array<any> = [
    isRequired('This field is required')
    // this.preCheckToken()
  ]

  showGroupTarif = false

  errors:any = []

  resetSubscriptionTypeID () {
    this.formSynced.currentSub = -1
    this.formSynced.subscriptiontypeid = null
    this.isUpdateSync=false
  }

  selectedSubscription (subscription : string) {
    this.formSynced.subscriptiontypeid = subscription
    this.formSynced.currentSub = 1
  }

  preCheckToken () {
    this.isChecking = true
    return (val: any) => {
        return this.checkToken(val)
    }
    /* if (this.formSynced.printednumber && this.formSynced.printednumber.length > 13) {
        try {
        const dt: any = await new TokenApi().check(this.formSynced.printednumber)
        this.checkState = 0
        } catch (e: any) {
        console.log('IN ERROR')
        console.log(e)
        this.checkState = e.status === 'WARNING' ? 1 : 2
        const msg = '$vuetify.errorTXT.' + e.errorcode
        this.errMSG = this.t(msg)
        }
        this.isChecking = false
        // return this.errMSG
    } */
    // return 'error'
  }

  async checkToken (val: string) {
    return 'tested'
  }

  removeToken () {
    this.$emit('removeToken')
  }

  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  errMSG = ''

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  get tokenColor () {
    let color = ''
    switch (this.checkState) {
        case 0: color = 'success'; break;
        case 1: color = 'warning'; break;
        case 2: color = 'error'; break;
        default:
            break;
    }
    return color
  }

  /* get checkToken () {
    return !this.checkState && this.formSynced.printednumber != null && this.formSynced.printednumber.length >= 13
  } */

  @Watch('formSynced.printednumber')
  printedNumberOnChanged (val: any) {
    if (val && val.length > 13) {
      this.checkPrintedNumber()
    } else {
      this.errors = []
    }
  }

  async checkPrintedNumber () {
    this.isChecking = true
    try {
      const dt: any = await new TokenApi().check(this.formSynced.printednumber)
      this.checkState = 0
    } catch (e: any) {
      this.checkState = e.status === 'WARNING' ? 1 : 2
      const msg = '$vuetify.errorTXT.' + e.errorcode
      this.errors = this.t(msg)
    }
    this.isChecking = false
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1 || this.formSynced.ChargepointSerialNumber === 'PIETER') {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        if (this.formSynced.ChargepointSerialNumber === 'PIETER') {
          this.isCheck = true
          this.state = 1
        } else {
          const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
          this.cp = rslt.data
          this.state = 1
        }
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep () {
    this.$store.commit('chargerInstallationState/setSerialNumber', this.formSynced.ChargepointSerialNumber)
    this.$emit('next')
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
