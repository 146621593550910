




































































import Vue from 'vue'
import Component from 'vue-class-component'
import StepOne from './stepper/StepOne.vue'
import StepSettings from './stepper/StepSettings.vue'
import StepTwo from './stepper/StepTwo.vue'
import StepThree from './stepper/StepThree.vue'
import StepFour from './stepper/StepFour.vue'
import StepSix from './stepper/StepSix.vue'
import StepOtherUsage from './stepper/StepOtherUsage.vue'
import StepInstallationType from './stepper/StepInstallationType.vue'
import StepMainUser from './stepper/StepMainUser.vue'
import ResultCards from './stepper/ResultCards.vue'
import PlugCharge from './stepper/PlugCharge.vue'
import ChargeCardAccess from './stepper/ChargeCardAccess.vue'
import ChargePointPlacementApi, { IPlacementCreate } from '@/services/ChargePointPlacementApi'
import ChargePointApi from '@/services/ChargePointApi'
import { mapGetters } from 'vuex'
import RelationApi from '@/services/RelationApi'
import RelationContactApi from '@/services/RelationContactApi'

@Component({
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepSix,
    PlugCharge,
    ChargeCardAccess,
    StepSettings,
    StepInstallationType,
    StepOtherUsage,
    StepMainUser,
    ResultCards
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', elm: 'chargerInstallationState/elmState' })
  },
  props:['is-mobile']
})
export default class AddChargepointModal extends Vue {
  currentStep = 'StepOne' // StepOne | StepSettings
  isSaving = false
  dialog = false
  successGuest = 0
  successWhiteList = 0
  succesPlacement = 0
  successPlugCharging = 0
  stepper = 0
  e13 = 1
  notifications= false
  sound= true
  widgets= false
  serialnumber = ''
  errMsg = ''
  relationId = 0
  showIcon = false
  isReset = false

  stepTab: any = []

  form: any = {
    isCompany: false,
    relationId: '',
    chargepointID: '',
    relationContactId: '',
    wasContact: false,
    isChargeCardAccess: false,
    ChargepointSerialNumber: '',
    location: {
      Label: ''
    },
    plugcharge: {
      isReimbursement: false,
      isPublishMap: false,
      tariffid: ''
    },
    guestcharge: {
      isGuestCharge: false,
      isPublishMap: false,
      isMapFree: false,
      isMapPaid: false,
      typeCharge: 0,
      tariffid: {
        Code: ''
      }
    },
    reimbursement: {
      type: 'NO'
    },
    chargerType: 'PRIVATE',
    gps: {
      latitude: 0,
      longitude: 0
    },
    chargeCard: {
      currentIndex: 0,
      isChargeCardAccess: false,
      groups: [],
      deleteGroups: []
    },
    Location: {
      id: '',
      Label: '',
      Description: '',
      Street: '',
      HouseNumber: '',
      City: '',
      ZipCode: '',
      Country: '',
      Latitude: 0,
      Longitude: 0
    },
    convert: {
      tab: 0,
      relationid: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      remarkinternal: '',
      remarkpublic: '',
      remark: '',
      isActive: null,
      hasportal: null,
      isentitymanager: null,
      bankaccountiban: '',
      bankaccountbic: ''
    }
  }

  errorTab: any = []

  mounted () {
    this.generateSteps()
  }

  finish () {
    // this.dialog = false
    // TODO CLEANUP
    this.cancel()
    this.$emit('refresh')
  }

  generateSteps () {
    if (!this._.isEmpty(this.usrDTA.data)) {
      // console.log(this.usrDTA)
      if (this.usrDTA.data.role === 'User' || !this.usrDTA.data.Relation.IsCompany) {
        this.stepTab = [
          {title: this.t('$vuetify.newCharger.serialNumber'), icon: 'uil uil-check', compName: 'StepOne'},
          {title: this.t('$vuetify.newCharger.address'), icon: 'uil uil-check', compName: 'StepThree'},
          {title: this.t('$vuetify.newCharger.settings'), icon: 'uil uil-check', compName: 'StepSettings'}
        ]
        // Configurate data for driver case :
        // console.log(this.usrDTA.data)
        const relationContact = this.usrDTA.data.RelationContact
        // Set default location of the current User
        this.form.Location = {
          id: '',
          Label: '',
          Description: '',
          Street: relationContact.Street,
          HouseNumber: relationContact.HouseNumber,
          City: relationContact.City,
          ZipCode: relationContact.PostalCode,
          Country: relationContact.Country,
          Latitude: 0,
          Longitude: 0
        }
      } else {
        this.stepTab = [
          {title: this.t('$vuetify.newCharger.serialNumber'), icon: 'uil uil-check', compName: 'StepOne'},
          {title: this.t('$vuetify.newCharger.location'), icon: 'uil uil-check', compName: 'StepInstallationType'},
          {title: this.t('$vuetify.newCharger.linked'), icon: 'uil uil-check', compName: 'StepMainUser'},
          {title: this.t('$vuetify.newCharger.address'), icon: 'uil uil-check', compName: 'StepThree'},
          {title: this.t('$vuetify.newCharger.settings'), icon: 'uil uil-check', compName: 'StepSettings'}
        ]
      }
    }
  }

  next () {
    this.isReset=false;
    this.currentStep = this.stepTab[this.e13].compName
    // console.log('NEXT')
    // console.log(this.e13)
    // console.log(this.stepTab[this.e13])
    this.e13 += 1
    // console.log('AFTER ++')
    // console.log(this.e13)
    // console.log(this.stepTab[this.e13])
    /* if (this.stepTab[(this.e13-1)].compName === 'StepSettings') {
      // console.log('TODO')
    } */
  }

  back () {
    // console.log('BACK')
    // console.log(this.e13)
    // console.log(this.stepTab[this.e13])
    if (this.e13 !== 0) {
      this.e13--
    }
    // console.log('AFTER --')
    // console.log(this.e13)
    // console.log(this.stepTab[this.e13])
    this.currentStep = this.stepTab[(this.e13-1)].compName
  }

  cancel () {
    // this.$emit('cancel')
    this.dialog = false
    this.currentStep = 'StepOne'
    this.e13 = 1
    this.form = {
      isCompany: false,
      relationId: '',
      chargepointID: '',
      relationContactId: '',
      wasContact: false,
      isChargeCardAccess: false,
      ChargepointSerialNumber: '',
      location: {
        Label: ''
      },
      plugcharge: {
        isReimbursement: false,
        isPublishMap: false,
        tariffid: ''
      },
      guestcharge: {
        isGuestCharge: false,
        isPublishMap: false,
        isMapFree: false,
        isMapPaid: false,
        typeCharge: 0,
        tariffid: {
          Code: ''
        }
      },
      reimbursement: {
        type: 'NO'
      },
      chargerType: 'PRIVATE',
      gps: {
        latitude: 0,
        longitude: 0
      },
      chargeCard: {
        currentIndex: 0,
        isChargeCardAccess: false,
        groups: [],
        deleteGroups: []
      },
      Location: {
        id: '',
        Label: '',
        Description: '',
        Street: '',
        HouseNumber: '',
        City: '',
        ZipCode: '',
        Country: '',
        Latitude: 0,
        Longitude: 0
      },
      convert: {
        tab: 0,
        relationid: '',
        firstname: '',
        lastname: '',
        telephone: '',
        email: '',
        street: '',
        housenumber: '',
        postalcode: '',
        city: '',
        remarkinternal: '',
        remarkpublic: '',
        remark: '',
        isActive: null,
        hasportal: null,
        isentitymanager: null,
        bankaccountiban: '',
        bankaccountbic: ''
      }
    }
    this.$store.commit('chargerInstallationState/setReset')
  }

  async saveSettings () {
    this.isSaving = true
    this.errorTab = []
    // check if company
    if (this.elmDTA.isCompany) {
      // create placement
      const chargePointId = await this.createPlacement(this.elmDTA.relationId)
      // const chargePointId = 3046
      // const chargePointId = -1
      if (chargePointId > 0) {
        // Plug & Charge flow
        if (this.elmDTA.isPlugCharge) {
          await this.createPlugCharge(chargePointId)
        } else {
          // Guest charg flow
          if (this.elmDTA.isGuestCharge) {
            await this.creatGuestCharge(chargePointId)
          }
          // White list flow
          if (this.elmDTA.accessCard.tokens.length > 0 || this.elmDTA.accessCard.rfidTokens.length > 0) {
            // Create new Group:
            const groupCode = await this.createGroup(chargePointId, this.elmDTA.relationId)
            if (groupCode !== -1) {
              // Create white list
              const whitelistCode = await this.createWhitelist(groupCode, this.elmDTA.accessCard.subscriptiontypeid, false)
              if (whitelistCode !== -1) {
                // Add Blue corner cards :
                const rslt = await this.addTokenWhitelist(this.elmDTA.accessCard.tokens, groupCode, whitelistCode)
                if (rslt !== -1) {
                  // Add Externe cards :
                  const rslt = await this.addExternTokenWhitelist(this.elmDTA.accessCard.rfidTokens, groupCode, whitelistCode)
                  if (rslt !== -1) {
                    // Add group to chargepoint
                    // TODO
                    const rslt = await this.addGroupChargepoint(groupCode, chargePointId)
                    // this.addReimbWhiteList(chargePointId, this.elmDTA.accessCard.subscriptiontypeid)
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // Check if create new User
      let relationId = -1
      // Check als if user is not a private
      if (this.usrDTA.data.Relation.IsCompany !== 0) {
        if (this.form.convert.tab === '0' ) {
          relationId = await this.createConvertRelation()
        } else {
          // Convert User and get relation ID
          relationId = await this.convertRelation()
        }
      } else {
        relationId = this.usrDTA.data.Relation.Id
      }
      if (relationId !== -1) {
        const chargePointId = await this.createPlacement(relationId)
        // const chargePointId = 3046
        if (this.elmDTA.isPlugCharge) {
          await this.createPlugCharge(chargePointId)
        } else {
            if (this.elmDTA.isGuestCharge) {
              await this.creatGuestCharge(chargePointId)
            }
            // White list flow
            if (this.elmDTA.accessCard.tokens.length > 0 || this.elmDTA.accessCard.rfidTokens.length > 0 || this.elmDTA.accessCard.tokensReimb.length > 0 ||  this.elmDTA.accessCard.rfidTokensReimb.length > 0) {
              // Create new Group:
              const groupCode = await this.createGroup(chargePointId, relationId)
              if (groupCode !== -1) {
                // Create white list
                // Check if the're free cards:
                if (this.elmDTA.accessCard.tokens.length > 0 || this.elmDTA.accessCard.rfidTokens.length > 0) {
                  const whitelistCode = await this.createWhitelist(groupCode, this.elmDTA.accessCard.subscriptiontypeid, false)
                  if (whitelistCode !== -1) {
                    // Add Blue corner cards :
                    const rslt = await this.addTokenWhitelist(this.elmDTA.accessCard.tokens, groupCode, whitelistCode)
                    if (rslt !== -1) {
                      // Add Externe cards :
                      const rslt = await this.addExternTokenWhitelist(this.elmDTA.accessCard.rfidTokens, groupCode, whitelistCode)
                    }
                  }
                }
                // Check if the're reimbursed cards
                if (this.elmDTA.accessCard.tokensReimb.length > 0 ||  this.elmDTA.accessCard.rfidTokensReimb.length > 0) {
                  const whitelistCode = await this.createWhitelist(groupCode, this.elmDTA.accessCard.reimbsubscriptiontypeid, false)
                  if (whitelistCode !== -1) {
                    // Add Blue corner cards :
                    const rslt = await this.addTokenWhitelist(this.elmDTA.accessCard.tokensReimb, groupCode, whitelistCode)
                    if (rslt !== -1) {
                      // Add Externe cards :
                      const rslt = await this.addExternTokenWhitelist(this.elmDTA.accessCard.rfidTokensReimb, groupCode, whitelistCode)
                    }
                  }
                }
                // Add group to chargepoint
                const rslt = await this.addGroupChargepoint(groupCode, chargePointId)
                // Active reimbursement if activated
                if (rslt !== -1 && this.elmDTA.accessCard.isReimburse) {
                  // this.elmDTA.accessCard.subscriptiontypeid
                  this.addReimbWhiteList(chargePointId, this.elmDTA.accessCard.reimbsubscriptiontypeid)
                }
              }
            }
        }
      }
    }
    this.isSaving = false
    this.currentStep = 'ResultCards'
    // console.log(this.elmDTA)
  }

  async createConvertRelation () {
    try {
      // console.log('CREATE CONVERT')
      const result = await new RelationContactApi().createUpdate(this.form.convert) as any
      const rcId = result.data.Id
      // console.log(rcId)
      const elmData = await new RelationApi().convertRelation(rcId) as any
      return elmData.data.relationid
    } catch (error) {
      this.errorTab.push(
        {code: 'INVCONVERT', content: 'There was an error when converting user to relation.'}
      )
      return -1
    }
    /* try {
      const elmData = await new RelationApi().convertRelation(this.form.relationContactId) as any
      return elmData.data.relationid
    } catch (error) {
      this.errorTab.push(
        {code: 'INVCONVERT', content: 'There was an error when converting user to relation.'}
      )
      return -1
    } */
    // return -1
  }

  async convertRelation () {
    try {
      // console.log('ONLY CONVERT')
      let elmData: any= -1
      if (this.usrDTA.data.role === 'User' || !this.usrDTA.data.Relation.IsCompany) {
        elmData = await new RelationApi().convertRelation(this.usrDTA.data.RelationContactId) as any
      } else {
        elmData = await new RelationApi().convertRelation(this.form.relationContactId) as any
      }
      return elmData.data.relationid
    } catch (error) {
      this.errorTab.push(
        {code: 'INVCONVERT', content: 'There was an error when converting user to relation.'}
      )
      return -1
    }
  }

  async addReimbWhiteList (chargepointID: any, subscriptiontypeid: any) {
    try {
      const dta = {
          toggle: 1,
          reimbsubtypeid: subscriptiontypeid,
          reimburserid: this.elmDTA.accessCard.reimburserId,
          presetid: ''
        }
      await new ChargePointApi().reimbWL(dta, chargepointID)
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVSETREIMBWHITELIST', content: 'There was an error setting reimburse to whitelist.'}
      )
      return -1
    }
  }

  async addGroupChargepoint (groupCode: any, chargepointID: any) {
    try {
      const payloadSetGroup = {
        WhiteListGroupCode: groupCode
      }
      await new RelationApi().setGroup(chargepointID, payloadSetGroup)
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVSETGRP', content: 'There was an error setting group to chargepoint.'}
      )
      return -1
    }
  }

  async addExternTokenWhitelist (tokens: any, groupCode: any, whitelistCode: any) {
    try {
      for (let index = 0; index < tokens.length; index++) {
        const element = tokens[index]
        const payload = {
          Id: element.Id,
          Description: element.Description
        }
        await new RelationApi().addPrintedAuthorizeNumber(groupCode, whitelistCode, payload)
      }
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVADDTOKENWHITELIST', content: 'There was an error adding tokens to whitelist.'}
      )
      return -1
    }
  }


  async addTokenWhitelist (tokens: any, groupCode: any, whitelistCode: any) {
    try {
      for (let index = 0; index < tokens.length; index++) {
        const element = tokens[index]
        const payload = {
          PrintedNumber: element.Id,
          Description: element.Description
        }
        await new RelationApi().addprintedNumber(groupCode, whitelistCode, payload)
      }
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVADDTOKENWHITELIST', content: 'There was an error adding tokens to whitelist.'}
      )
      return -1
    }
  }

  async createWhitelist(groupCode: any, subscriptiontypeid: any, isReimburse: any) {
    try {
      // Add Create Whitelist costless :
      const payload = {
        Description: subscriptiontypeid,
        TariffCode: subscriptiontypeid,
        InvoiceType: '',
        InvoiceRelationId: ''
      }
      if (isReimburse) {
        payload.InvoiceType = 'TO'
        payload.InvoiceRelationId = this.elmDTA.accessCard.reimburserId
      }
      const bankRslt: any = await new RelationApi().createWhiteList(groupCode, payload)
      return bankRslt.data.Code
    } catch (error) {
      this.errorTab.push(
        {code: 'INVWHITELISTGRP', content: 'There was an error creating white list.'}
      )
      return -1
    }
  }

  async createGroup (chargepointID: any, relationID: any) {
    try {
      const payload = {
        Code: 'R' + relationID + 'CP' + chargepointID,
        Description: 'G' + 'R' + relationID + 'CP' + chargepointID,
        WhiteListList: []
      }
      const data = await new RelationApi().createGroup(relationID, payload) as any
      return data.data.Code
    } catch (error) {
      this.errorTab.push(
        {code: 'INVGRPTOKEN', content: 'There was an error creating tokens group.'}
      )
      return -1
    }
  }

  async creatGuestCharge (id: any) {
    try {
      const payload = {
        toggle: this.elmDTA.isGuestCharge ? 1 : 0,
        published: this.elmDTA.isPublishMap ? 1 : 0,
        reimburse: this.elmDTA.guestcharge.isReimburse ? 1 : 0,
        isfree: this.elmDTA.guestcharge.isFree ? 1 : 0,
        isnetworkpartner : this.elmDTA.guestcharge.isBlueCornerFee ? 1 : 0,
        reimburser: '', // will be changed
        customfee: this.elmDTA.guestcharge.tariffid.Code, // terug betaling
        subscriptiontypeid: 'SUB-PARTNER-UPPER-NOUPLIFT' //  this.elmDTA.guestcharge.tariffid.Code
      }
      await new ChargePointApi().guestCharging(payload, id)
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVGUESTCHARGE', content: 'There was an error activating guest charging.'}
      )
      return -1
    }
  }

  async createPlugCharge (id: any) {
    try {
      const payload = {
        toggle: this.elmDTA.isPlugCharge ? 1 : 0,
        reimburse: this.elmDTA.plugcharge.isReimbursement ? 1 : 0,
        reimburser: this.elmDTA.plugcharge.reimburser,
        customfee: this.elmDTA.plugcharge.customfee,
        subscriptiontypeid: this.elmDTA.plugcharge.tariffid.Code,
      }
      await new ChargePointApi().plugCharging(payload, id)
      return 1
    } catch (error) {
      this.errorTab.push(
        {code: 'INVPLUGCHARGE', content: 'There was an error activating plug & charge.'}
      )
      return -1
    }
  }

  async createPlacement (relationID: any) {
    try {
      const out: any = {
        chargepointserialnumber: this.elmDTA.serialNumber,
        chargertype: this.elmDTA.chargertype, // [TODO] zien als het ander moet ?
        relationid: relationID,
        location: {
          relationid: relationID,
          street: this.elmDTA.location.street,
          city: this.elmDTA.location.city,
          name: this.elmDTA.location.label,
          description: this.elmDTA.location.description,
          housenumber: this.elmDTA.location.housenumber,
          postalcode: this.elmDTA.location.postalcode,
          latitude: this.elmDTA.location.latitude,
          longitude: this.elmDTA.location.longitude,
          country: this.elmDTA.location.country
        }
      }
      const data: any = await ChargePointPlacementApi.createPlacement(out, 0)
      return data.data.chargePointId
    } catch (error) {
      this.errorTab.push(
        {code: 'INVCPPL', content: 'There was an error connecting the charging point to the location.'}
      )
      return -1
    }
  }

  reset () {
    this.dialog = false
    this.stepper = 1
    this.succesPlacement = 0
    this.successPlugCharging = 0
    this.successGuest = 0
    this.successWhiteList = 0
    this.form = {
    isCompany: false,
    relationId: '',
    relationContactId: '',
    wasContact: false,
    isChargeCardAccess: false,
    ChargepointSerialNumber: '',
    chargepointID: '',
    location: {
      Label: ''
    },
    plugcharge: {
      isReimbursement: false,
      isPublishMap: false,
      tariffid: ''
    },
    guestcharge: {
      isGuestCharge: false,
      isPublishMap: false,
      isMapFree: false,
      isMapPaid: false,
      typeCharge: 0,
      tariffid: {
        Code: ''
      }
    },
    reimbursement: {
      type: 'NO'
    },
    chargerType: 'PRIVATE',
    gps: {
      latitude: 0,
      longitude: 0
    },
    chargeCard: {
      currentIndex: 0,
      isChargeCardAccess: false,
      groups: [],
      deleteGroups: []
    },
    Location: {
      id: '',
      Label: '',
      Description: '',
      Street: '',
      HouseNumber: '',
      City: '',
      ZipCode: '',
      Country: '',
      Latitude: 0,
      Longitude: 0
    },
    convert: {
      tab: 0,
      relationid: '',
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      remarkinternal: '',
      remarkpublic: '',
      remark: '',
      isActive: null,
      hasportal: null,
      isentitymanager: null,
      bankaccountiban: '',
      bankaccountbic: ''
    }
    }
    this.isReset = true
    this.currentStep = 'StepOne'
    this.e13 = 1
  }

  closeAll () {
    this.reset()
    this.$emit('refresh')
  }

  get usrDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  get elmDTA (): any {
    // @ts-ignore
    return this.elm
  }

  /* cancel () {
    this.reset()
    // this.$emit('refresh')
  } */

  refresh () {
    this.dialog = false
  }
}
