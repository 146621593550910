





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import moment from 'moment'
import RelationApi from '@/services/RelationApi'
import MapApi from '@/services/MapApi'
import { mapGetters } from 'vuex'
import ChargePointPlacementApi, { IPlacementCreate } from '@/services/ChargePointPlacementApi'
import ChargePointApi from '@/services/ChargePointApi'

@Component({
  filters: {
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Object) readonly cp: any
  @Prop(String) readonly chargepointId: any
  @PropSync('form', { type: Object }) formSynced!: any

  tmpCp= {
    Locations: []
  }

  loadTarif = false
  dialog = false
  step = 0
  isMapFree = false
  isPublishMap = false
  isPlugCharge = false
  isMapPaid = false
  isReimbursementPlug = false
  radioGroupGuestCharging = 1

  payload = {
    toggle: 0, // Enable or disable guestcharging
    published: 0, // Make charge public (will show on map, ...)
    reimburse: 0, // Reimburse or no reimburse?
    tariffid: '' // Tariff for reimbursement
  }

  headersTokensGroup = [
    { text: 'Charge card', value: 'group' },
    { text: 'Reimbursement', value: 'action' },
    { text: '', value: 'action' },
    { text: '', value: 'action' }
  ]

  itemBanks = [
    'Jan Peeters - KBC, BE93 0710 9969 5909',
    'Jan Peeters - ING, BE16 1530 4946 9750'
  ]

  tokensGroup = []
  tokenList: any = []
  relationId = 0
  isChargeCardAccess = false
  isDuplicate = false
  isEditNameGroup = false
  isChargeCardReimbursement = false
  isReimbursement = false
  isCreateTariff = false
  newToken = null
  errMsg = ''

  itemsTariff = [
    { Description: this.t('$vuetify.subscriptions.SUB_PARTNER_UPPER_NOUPLIFT'), Code: 'SUB-PARTNER-UPPER-NOUPLIFT' }
  ]

  next () {
    this.$emit('next')
  }

  async saveDTA () {
    this.$emit('save')
  }

  back () {
    this.$emit('back')
  }

  cancel () {
    this.cancelChargeCardAccess()
    this.$emit('cancel')
  }

  get userData (): any {
    // @ts-ignore
    return this.userDTA
  }

  get contractData (): any {
    // @ts-ignore
    return this.contractDTA
  }

  async getListTariff () {
    try {
      this.loadTarif = true
      const data = await new RelationApi().getTariffs(this.formSynced.relationId) as any
      this.itemsTariff = data.data.records
      this.loadTarif = false
    } catch (error) {
      console.log(error)
    }
  }

  async save () {
    // console.log('[DEBUG] -- Save')
    // console.log(this.cp.Locations[(this.cp.Locations.length - 1)])
    const payload = {
      id: parseInt(this.cp.Locations[(this.cp.Locations.length - 1)].Id),
      // [TODO] - name: this.cp.Locations[(this.cp.Locations.length - 1)].Country,
      description: this.cp.Locations[(this.cp.Locations.length - 1)].Description,
      landownerid: this.cp.Locations[(this.cp.Locations.length - 1)].LandOwnerId,
      street: this.cp.Locations[(this.cp.Locations.length - 1)].Street,
      housenr: this.cp.Locations[(this.cp.Locations.length - 1)].HouseNumber,
      city: this.cp.Locations[(this.cp.Locations.length - 1)].City,
      postalcode: this.cp.Locations[(this.cp.Locations.length - 1)].ZipCode,
      country: this.cp.Locations[(this.cp.Locations.length - 1)].Country,
      lng: this.cp.Locations[(this.cp.Locations.length - 1)].Latitude,
      lat: this.cp.Locations[(this.cp.Locations.length - 1)].Longitude,
      phone: this.cp.Locations[(this.cp.Locations.length - 1)].Phone
    }
    try {
      const result: any = await new MapApi().createUpdate(payload)
      // console.log(result)
    } catch (error) {
      console.log(error)
    }
    /* console.log('[DEBUG SAVE GUEST CHARGING]')
    console.log(this.payload)
    // disabled
    if (this.radioGroupGuestCharging === 0) {
      console.log('DISABLED')
      this.payload = {
        toggle: 0,
        published: 0,
        reimburse: 0,
        tariffid: ''
      }
      try {
        const result: any = await new ().guestCharging(this.payload, this.chargepointId)
        console.log(result)
        // this.relationId = result.data.data.Relation.Id
      } catch (error) {
        console.error(error)
      }
    } else if (this.radioGroupGuestCharging === 1) {
      console.log('FREE')
    } else {
      console.log('PAID')
    } */
  }

  startConfiguration () {
    this.getListTariff()
    this.dialog = true
    this.step = 0
  }

  cancelChargeCardAccess () {
    this.dialog = false
    this.step = 0
    this.isChargeCardAccess = false
    this.isChargeCardReimbursement = false
    this.isReimbursement = false
    this.isCreateTariff = false
  }
}
