import TokenAllApi from '@/services/TokenAllApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue/types/umd'
import { VBtn, VIcon, VDialog, VCard, VCardTitle, VCardText, VAutocomplete, VListItemContent, VListItemTitle, VListItemSubtitle, VCardActions, VSpacer } from 'vuetify/lib'
import BcBtn from '../_base/BcBtn'
import ChargePointApi from '@/services/ChargePointApi'

@Component
export default class Unlock extends Vue {
  @Prop(Object) readonly placement: any

  loading = false
  tokens: Array<any> = []
  selectedToken: any = null
  showSynced = false
  status = ''

  render (h: CreateElement) {
    return h(VDialog, {
      scopedSlots: {
        activator: this.$scopedSlots.activator
      },
      props: {
        value: this.showSynced,
        width: 800
      },
      on: {
        input: (val: boolean) => { this.showSynced = val }
      }
    }, [
      h(VCard, [
        h(VCardTitle, ['Unlock']),
        h(VCardText, ['Warning any active session on this connector will be stopped, use with caution']),
        this.actions
      ])
    ])
  }

  mounted () {
    this.fetchTokens()
  }

  async fetchTokens () {
    const dt = await new TokenAllApi().myTokens() as any
    this.tokens = dt.data
  }

  get actions () {
    return this.$createElement(VCardActions, [
      this.$createElement(VSpacer),
      this.$createElement(BcBtn, {
        on: {
          click: () => this.unlock()
        },
        props: { loading: this.loading }
      }, 'Unlock'),
      this.$createElement(BcBtn, {
        on: {
          click: () => { this.showSynced = false }
        }
      }, 'Close')
    ])
  }

  async unlock () {
    if (this.placement) {
      this.loading = true
      const dt = await new ChargePointApi().unlock(this.placement.Id) as any
      this.loading = false
      if (dt.result.status === 'success') {
        this.status = 'success'
      } else {
        this.status = 'error'
      }
    }
  }

  get disabledState () {
    if (this.selectedToken) {
      return false
    }
    return true
  }
}
