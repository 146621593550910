















































import ChargePointApi from '@/services/ChargePointApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AutocompletePresetTokenList from '../../presets/AutocompletePresetSubscriptionList.vue'
@Component({
  components: {
    AutocompletePresetTokenList
  },
  computed: {
    ...mapGetters({ currentStep: 'chargerInstallationState/stepName', userDT: 'user/userInfo'})
  }
})
export default class StepOne extends Vue {
  @PropSync('form', { type: Object }) formSynced!: any
  @Ref() readonly formCPSN!: any
  @Prop(Boolean) readonly isReset: any

  radios = '1'

  // form
  isFormValid= true

  // form error
  error= false
  errorMessages= ''

  errorProvider= false
  errorProviderMessages= ''

  loading = false
  state = 0
  isCheck = false

  // input rules
  rules = {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  customFee = ''

  // error: string | null = null
  icon = ''
  color = 'primary'
  cp = {
    location: {
      Street: '',
      HouseNumber: '',
      ZipCode: '',
      City: '',
      Country: ''
    }
  }

  get errorState () {
    if (this.error) {
      return true
    }
    return false
  }

  next () {
    // Custom Fee
    if (this.radios === '2') {
      this.$store.commit('chargerInstallationState/setGuestchargeIsFree', false)
      this.$store.commit('chargerInstallationState/setGuestchargeIsBlueCornerFee', false)
      this.$store.commit('chargerInstallationState/setIsCustomFee', true)
      // Set data
      const dtaBis = {
        isCustomfee : true,
        val: this.customFee
      }
      // console.log('DEBUG SET REIMBURSE OP TRUE')
      this.$store.commit('chargerInstallationState/setGuestChargeIsReimbursement', true)
      this.$store.commit('chargerInstallationState/setGuestChargeCustomFee', dtaBis)
      this.$store.commit('chargerInstallationState/setStep', 'StepCardAccess')
    } else {
      // Free
      if (this.radios === '1') {
        this.$store.commit('chargerInstallationState/setGuestchargeIsFree', true)
        this.$store.commit('chargerInstallationState/setGuestchargeIsBlueCornerFee', false)
        this.$store.commit('chargerInstallationState/setIsCustomFee', false)
        // Set data
        const dtaBis = {
          isCustomfee : false,
          val: null
        }
        this.$store.commit('chargerInstallationState/setGuestChargeCustomFee', dtaBis)
        this.$store.commit('chargerInstallationState/setStep', 'StepVisible')
      } else {
        if(this.radios === '3') {
          this.$store.commit('chargerInstallationState/setGuestchargeIsFree', false)
          this.$store.commit('chargerInstallationState/setGuestchargeIsBlueCornerFee', true)
          this.$store.commit('chargerInstallationState/setIsCustomFee', false)
          // Set data
          const dtaBis = {
            isCustomfee : false,
            val: null
          }
          this.$store.commit('chargerInstallationState/setGuestChargeCustomFee', dtaBis)
          this.$store.commit('chargerInstallationState/setStep', 'StepCardAccess')
        } else {
          this.$store.commit('chargerInstallationState/setGuestchargeIsFree', false)
          this.$store.commit('chargerInstallationState/setGuestchargeIsBlueCornerFee', false)
          this.$store.commit('chargerInstallationState/setIsCustomFee', true)
          // Set data
          const dtaBis = {
            isCustomfee : true,
            val: 'SUB_PARTNER_CREG'
          }
          this.$store.commit('chargerInstallationState/setGuestChargeCustomFee', dtaBis)
          this.$store.commit('chargerInstallationState/setStep', 'StepCardAccess')
        }
      }
      // this.$store.commit('chargerInstallationState/setStep', 'StepVisible')
    }
  }

  reset () {
    this.state = 0
    this.resetErrors()
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
    this.formSynced.ChargepointSerialNumber = ''
    this.isCheck = false
    this.loading = false
  }

  getColor (connector: any) {
    if (connector.state === 'OCCUPIED') {
      return connector.sessionstate
    }
    return connector.state
  }

  async validate () {
    this.isCheck = true
    this.loading = true
    const res = await new ChargePointApi().verifyChargePoint(this.formSynced.ChargepointSerialNumber) as any
    if (res.Verified === 1) {
      this.formSynced.chargepointid = res.ChargePointId
      try {
        const rslt = await new ChargePointApi().minimumDetails(this.formSynced.chargepointid) as any
        this.cp = rslt.data
        this.state = 1
      } catch (error) {
        this.showError(error.obj.result.message)
      }
    } else {
      this.showError('Invalid serial number')
    }
    this.loading = false
  }

  showError (msg: string) {
    this.error = true
    this.errorMessages = msg
    this.errorProvider = true
    this.errorProviderMessages = msg
    this.state = 2
    this.isCheck = false
    this.loading = false
  }

  submit () {
    if (this.formCPSN.validate()) {
      this.validate()
    }
  }

  cleanUp () {
    this.isCheck = false
    this.cp = {
      location: {
        Street: '',
        HouseNumber: '',
        ZipCode: '',
        City: '',
        Country: ''
      }
    }
  }

  nextStep () {
    this.$emit('next')
  }

  cancel () {
    this.cleanUp()
    this.reset()
    this.$emit('cancel')
  }

  async timer (ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('state')
  stateOnChanged (val: any, oldVal: any) {
    if (val === 1) {
      this.icon = 'mdi-check-circle'
      this.color = 'success'
    } else if (val === 2) {
      this.icon = 'mdi-close-circle'
      this.color = 'error'
    } else {
      this.icon = ''
      this.color = 'primary'
    }
  }

  @Watch('isReset')
  selectedOnChanged (val: any, oldVal: any) {
    if (val) {
      this.reset()
    }
  }
}
